@import "src/styles/variables";

.folder-item-extra {
    display: flex;
    justify-content: space-between;


    &__icons {
        display: flex;
        gap: 2.1rem;
        align-items: center;
        margin-right: 2rem;

        .config-item-form {
            display: flex;
            align-items: center;
        }
    }
}