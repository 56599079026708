@import 'src/styles/variables';

.description__modal {
    &__container {
        .wzmh-modal-body {
            width: 100%;
            flex-direction: column;
        }

        .wzmh-modal-close {
            background-color: transparent !important;

            &:hover {
                background-color: $primary-light-80 !important;

                svg {
                    fill: $primary-color !important;
                }
            }
        }
    }

    &__title {
        width: 100%;
        font-size: 2rem;
        font-weight: bold;
    }

    &__subtitle {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.6rem;
        text-transform: capitalize;

        .icon {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 1.6rem;
            aspect-ratio: 1;

            &:hover {
                fill: $primary-color;
            }
        }
    }

    &__desc {
        max-height: calc($prototype-view-height - 24rem);
        overflow-y: scroll;
    }
}