@import "src/styles/variables";

.sustainability-filter {
    &.unsustainable {
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        -ms-filter: grayscale(100%);
        -o-filter: grayscale(100%);
        filter: grayscale(100%);

        .sustainability-filter__content {
            border-bottom-left-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;

            &__form {
                &-label {
                    color: $grey;
                }

                &-desc {
                    color: $grey-2;
                }
            }
        }

        .sustainability-filter__illustration {
            opacity: 0;
            transition: opacity ease-in-out 0.2s;

            &::after {
                bottom: 10rem;
            }
        }
    }

    .icon--sustainable {
        width: 3rem;
        height: 3rem;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 1rem;
        position: relative;

        background-color: transparentize($color: $sustainable-product-light, $amount: 0.85);
        padding: 1rem;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;

        &__form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .switch__field {
                background: transparent;
                padding: 0;
            }

            &-label {
                font-size: 1.8rem;
                color: $sustainable-product;
            }

            &-desc {
                flex: 0 0 100%;
                font-size: 1.4rem;
                color: $sustainable-product-light;
                text-transform: none;
            }
        }
    }

    &__illustration {
        position: relative;
        opacity: 1;
        transition: opacity ease-in-out 0.35s;
        overflow: hidden;


        img {
            width: 100%;
        }

        &::after {
            transition: bottom ease-in-out 0.15s;
            content: "";
            display: block;
            position: absolute;
            bottom: 1rem;
            left: 0;
            width: 100%;
            height: 10rem;
            background-color: transparentize($color: $sustainable-product-light, $amount: 0.85);
        }
    }
}