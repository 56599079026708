@import "src/styles/variables";

.tabs {
    .wzmh-tabs {
        gap: 1.6rem;

        &.wzmh-tabs-left {
            .wzmh-tabs-nav-wrap {

                .wzmh-tabs-tab {
                    padding: 0.8rem 1.2rem;
                    text-transform: capitalize;
                    min-width: 17.5rem;

                    &-btn {
                        color: $grey;
                    }
                }
            }

            .wzmh-tabs-tab-active {
                background-color: rgba($color: $primary-color, $alpha: 0.1);
                border-inline-start: 16px;
                border-left: 2px solid $primary-color;

            }

            .wzmh-tabs-ink-bar {
                display: none;
            }
        }

        &.wzmh-tabs-top {
            .wzmh-tabs-tab-active {
                .wzmh-tabs-tab-btn {
                    color: $primary-color;
                }
            }

            .wzmh-tabs-tab-btn {
                color: $grey-2;
            }
        }
    }

    .wzmh-tabs-content-holder:has(.wzmh-tabs-left) {
        border-left: 1px solid $grey-4;
    }
}