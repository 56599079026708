@import "src/styles/variables";

.login {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    gap: 4.8rem;
    background: url('../../../assets/images/login-page-bg.jpeg') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // background-position-y: 20px;


    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        position: relative;
        margin-top: 16rem;

        &__bg {
            margin-top: -30rem;
        }

        &__content {
            display: flex;
            flex-direction: column;
            align-items: center;


            &__title {
                margin-block: 2.4rem 1.6rem;
                font-family: "DM Sans";
                font-size: 4.5rem;
                font-weight: 300;
                color: $grey-6;
            }

            &__description {
                font-size: 1.6rem;
                color: $grey-7;
                font-family: "DM Sans";
            }
        }


    }

    &__form {
        min-width: 35rem;

        form {
            display: flex;
            flex-direction: column;
            gap: 3.2rem;
        }

        button {
            border-radius: 0px;
        }

        span {
            color: white;
            font-size: 1.5rem;
        }

        &__input {
            border-radius: 0px;
            background-color: white !important;

            &[type="email"] {
                height: 50px;
            }
        }
    }

}

.login__form__password-input {
    &:hover {
        border-width: 2px !important;
    }
}

.login__form__email-input {
    &:hover {
        border-width: 2px !important;
        border-color: $primary-color !important;
    }
}