@import "src/styles/variables";

.image-preview {
    position: fixed;
    inset: 0;
    display: flex;
    z-index: 1000;
    background-color: rgba($color: $black, $alpha: 0.7);
    flex-direction: column;

    &__header {
        color: $white;
        display: flex;
        height: $navbar-height;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;
        align-items: center;
        padding: 1.4rem 6rem;
        background-color: $black;

        .icon--delete,
        .icon--back {
            fill: $white;
            cursor: pointer;
        }
    }

    &__content {
        margin: auto;
    }
}