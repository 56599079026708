@import "src/styles/variables";

.acoustic-performance-detail {
  .wzmh-collapse-content-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin-top: 2.4rem;

    .text-grey-2 {
      font-size: 1.4rem;
      width: 50%;
    }

    .acoustic-performance-detail__value {
      font-size: 1.5rem;
      width: 50%;
    }
  }
}

.icon--acoustic {
  width: 1.6rem;
  height: 0.8rem;
}
