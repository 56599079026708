@import "src/styles/variables";

.layer-variant-details {
  padding: 3.2rem 3.2rem 0 4rem;
  position: relative;
  width: 100%;

  // .wzmh-collapse-header {
  //   width: 90%;
  // }

  .product-name {
    font-size: 2rem;
    font-family: "AvenirBlack";
    color: $grey;
    margin: 0;
  }

  .view-product-content__start {
    width: 100%;
    max-width: 30rem;
  }

  .view-product-content__image {
    height: 30rem;
    width: 30rem;
    object-fit: contain;
  }

  .width-100 {
    width: 60vw;
  }

  &__action {
    & > div {
      max-width: 100%;
    }

    &s {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      max-width: 100%;
    }

    border-radius: 8px;
    border: 1px solid $grey-4;
    height: 3.2rem;
    width: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.4rem;
    cursor: pointer;

    .icon {
      height: 1.2rem;
      width: 1.2rem;
    }

    svg {
      fill: $grey-2 !important;
    }

    &:hover {
      border-color: $primary-light-60;

      svg {
        fill: $primary-color !important;
      }
    }
  }

  .variant-details {
    &__columns {
      display: flex;
      width: 100%;
      position: relative;
    }

    &__left-column {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
    }

    &__right-column {
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    &__card {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      margin-bottom: 2.4rem;
    }

    &__section-title {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 1.6rem;
      color: black;
    }
  }
}

// .variant-details__labels_label {
//   font-size: 1.6rem;
//   font-weight: 500;
//   color: black;
//   // font-family: "AvenirMedium", sans-serif;
// }

// .variant-details__values_label {
//   font-size: 1.5rem;
//   font-weight: 400;
//   color: #595959;
//   // font-family: "AvenirMedium", sans-serif;
// }

.variant-details__header {
  border-bottom: 1px solid $black;
}
