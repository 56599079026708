@import "src/styles/variables";

.sustainability {
    padding: 1.6rem;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;


    &.sustainable {
        background-color: $sustainable;
        border-radius: 6px;

        .sustainable__header {
            display: flex;
            gap: 1rem;
            align-items: center;

            &__title {
                color: $green;
            }
        }
    }
}