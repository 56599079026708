.card-container {
  margin-bottom: 1.6rem;
  transition: all 0.3s ease-in-out;
  max-width: 100%;
  padding: 0.5rem;

  &:hover {
    transform: translateY(-0.2rem);
  }

  .space-list {
    padding: 1.1rem 1.1rem 1.1rem 1.8rem;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;

    &.selected {
      background-color: rgba(0, 0, 0, 0.04);
      padding: 1.6rem 1.1rem 1.6rem 1.8rem;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }

  .plan-or-layer-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem;
    border-radius: 8px;
    cursor: pointer;

    &__details {
      display: flex;
      align-items: center;
      gap: 1.2rem;
    }

    &__title {
      font-family: "DM Sans";
    }

    &__actions {
      display: flex;
      gap: 0.8rem;

      .dual-view-icons__icon-gp__icon {
        border-radius: 8px;
        height: 3.2rem;
        width: 3.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1.4rem;
        border: none;
        cursor: pointer;

        .icon {
          fill: #666;
          height: 1.2rem;
          width: 1.2rem;
          opacity: 0.4;

          &--edit {
            opacity: 0.4;
          }

          &--accordion {
            fill: none !important;
            stroke: #1890ff !important;
            width: 80px !important;
            height: 80px !important;
          }

          &--accordion--closed {
            -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
          }
        }

        &.icon-hidden {
          display: none;
        }

        &:hover .icon {
          fill: #1890ff;
        }
      }
    }

    &.expanded {
      background-color: rgba(0, 0, 0, 0.04);

      .icon--edit {
        opacity: 1 !important;
      }
    }
  }

  .products-list {
    padding: 1.6rem;
  }
}
