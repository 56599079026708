@import "src/styles/variables";

.status-update {

    .switch__field {
        padding-inline: 0;

        &__label {
            &--active {
                color: $green;
            }

            &--inactive {
                color: $danger-color;

            }
        }
    }
}