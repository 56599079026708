@import "src/styles/variables";

.activity-notifications-drawer {
    $padding: 2rem 2.5rem;

    .wzmh-drawer-body {
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1.6rem;
        box-shadow: 0px 10px 30px rgba($color: $grey, $alpha: 0.07);
        padding: $padding;


        &__title {
            font-size: 2.4rem;
            margin-right: auto;
        }
    }

    &__main {
        padding: $padding;
    }

}

.activity-notifications-drawer-root {
    .wzmh-drawer-content-wrapper {
        min-width: 56.5rem;
    }
}