@import "src/styles/variables";


.wzmh-slider{
    .wzmh-slider-handle, .wzmh-slider-handle:hover::after, .wzmh-slider-handle:focus::after,
        .wzmh-slider-handle:active::after{
        box-shadow: none;
    }
    .wzmh-slider-handle:hover::before, .wzmh-slider-handle:focus::before,
        .wzmh-slider-handle:active::before {
            box-shadow: none;
    }
    .wzmh-slider-handle::after{
            background-color: $primary-color;
            box-shadow: none;
    }
    
    .wzmh-slider-track{
        background-color: $primary-color;
    }
    :hover .wzmh-slider-track{
        background-color: $primary-color;
    }
    .wzmh-slider-handle-2:hover::after, .wzmh-slider-handle-2:active::after, .wzmh-slider-handle-2:focus::after {
        box-shadow: none;
    }
}