@import "src/styles/variables";

.contained-text-area {
    display: flex;
    flex-direction: column;
    padding-block-start: 1.6rem;
    padding-inline-start: 1.2rem;
    gap: 0.4rem;
    border: 1px solid $grey-4;
    border-radius: 8px;

    &__header {
        display: flex;
        gap: 1.2rem;
        align-items: center;
        color: $grey-2;
    }

    textarea {
        padding-inline: 3rem 0;
        font-size: 1.5rem;
    }
}