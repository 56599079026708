.table .table-container {
    .expanded-row {
        .wzmh-table-wrapper .wzmh-table {
            border-radius: 0;
        }
    }

    .product-list__image{
        margin-right: 1rem;
        width: 28px;
    }
}