.acoustic-performance {
  &__fields {
    display: flex;

    // Add borders between inputs
    :not(:last-child) {
      border-right: 1px solid #e8e8e8; // Light gray border
    }

    // Make inputs take equal width
    > div {
      flex: 1;
    }
  }
}
