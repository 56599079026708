@import "src/styles/variables";

.product-form {
    padding: 1.7rem 4rem;


    .loader {
        position: fixed;
        padding: 1.7rem 4rem;
        inset: 0;
        inset-block-start: $navbar-height;
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 3.2rem;

        .input-error {
            display: none;
        }
    }
}