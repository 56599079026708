@import "src/styles/variables";

.update-variant-title {
  display: flex;
  gap: 10px;
  // overflow-y: scroll;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding-top: 1.2rem;

  .button.wzmh-btn-link {
    padding: 0;
  }

  .icon--edit {
    visibility: hidden;
  }

  &:hover {
    .icon--edit {
      visibility: visible;
    }
  }

  &__item {
    &:not(:first-child) {
      padding-left: 0.5rem;
      border-left: 1px solid $grey-3;
    }
  }
}

.add-variant-overlay {
  .wzmh-popover-inner {
    min-width: 53.7rem;
  }

  .add-variant-popover {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__end {
        display: flex;
        gap: 2rem;
      }
    }

    hr {
      margin-bottom: 2rem;
    }
  }
}
