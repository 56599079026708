@import "src/styles/_main";

.picklist {
  &__container {
    margin: 2.6rem 10rem;

    .wzmh-select-selection-item {
      font-family: "AvenirBlack", sans-serif;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__buttons {
      display: flex;
      position: relative;
      width: max-content;
      gap: 10px;
      justify-content: space-between;

      &--button {
        margin-inline: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        margin: 0;

        &:first-child {
          margin-inline-start: 0;
        }

        &:last-child {
          margin-inline-end: 0;
        }

        svg {
          height: 1.8rem;
          width: 1.8rem;
          fill: $grey-2;
        }
      }

      &--export {
        div,
        button {
          display: block !important;
        }
      }

      .button--options {
        svg {
          height: 2.4rem;
          width: 2.4rem;
        }
      }
    }

    &__selector {
      cursor: pointer;
      width: max-content !important;

      .wzmh-select-selection-item {
        font-size: 2.2rem;
      }

      .wzmh-select-single {
        width: max-content !important;
      }

      .wzmh-select-single:not(.wzmh-select-customize-input)
        .wzmh-select-selector {
        border: 0;

        &:after {
          color: $primary-color;
        }
      }
    }

    .switch {
      width: 100%;
    }
  }

  &--popover {
    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        line-height: 1rem;
        margin-block-start: 10px;
        margin-block-end: 10px;
        cursor: pointer;
      }

      .primary {
        color: $primary-color;
      }
    }
  }
}

.picklist-main {
  padding-block: 2rem;

  &.picklist-open .wzmh-table-cell-row-hover {
    .action {
      display: block !important;
    }
  }

  &.picklist-close {
    .action {
      display: none;
    }
  }

  .picklist-table-container {
    .wzmh-pagination {
      display: none;
    }

    table {
      border: 1px solid $grey-4;
    }

    th {
      font-size: 12px;
      text-transform: uppercase;
      text-align: center !important;
    }

    .product__name,
    .product__quantity {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;

      &-image {
        width: 32px !important;
        aspect-ratio: 1;

        svg {
          display: none;
        }
      }
    }

    .product__quantity {
      gap: 1rem;
      justify-content: center;

      .value {
        text-align: center;
        min-width: 2ch;
      }

      .action {
        color: $primary-color;
        font-size: 1.6rem;
        cursor: pointer;
        display: none;
      }
    }

    .product-remove-button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 1.4rem;

      svg {
        cursor: pointer;
        height: 1.4rem;
        fill: $grey-2;
        display: none;
      }
    }
  }
}

.picklist-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__buttons {
    display: flex;
    position: relative;
    width: max-content;
    gap: 10px;
    justify-content: space-between;

    &--button {
      margin-inline: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      margin: 0;

      &:first-child {
        margin-inline-start: 0;
      }

      &:last-child {
        margin-inline-end: 0;
      }

      svg {
        height: 1.8rem;
        width: 1.8rem;
        fill: $grey-2;
      }
    }

    &--export {
      div,
      button {
        display: block !important;
      }
    }

    .button--options {
      svg {
        height: 2.4rem;
        width: 2.4rem;
      }
    }
  }

  &__selector {
    cursor: pointer;
    width: max-content !important;

    .wzmh-select-selection-item {
      font-size: 2.2rem;
    }

    .wzmh-select-single {
      width: max-content !important;
    }

    .wzmh-select-single:not(.wzmh-select-customize-input)
      .wzmh-select-selector {
      border: 0;

      &:after {
        color: $primary-color;
      }
    }
  }

  .switch {
    width: 100%;
  }

  &--popover {
    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        line-height: 1rem;
        margin-block-start: 10px;
        margin-block-end: 10px;
        cursor: pointer;
      }

      .primary {
        color: $primary-color;
      }
    }
  }
}
.wzmh-table-row {
  &:hover {
    background-color: transparentize($color: $grey-2, $amount: 0.95) !important;
    span,
    &.wzmh-btn-text {
      text-decoration: none !important;
    }
  }
}

.picklist-main {
  padding-block: 2rem;

  &.picklist-open .wzmh-table-cell-row-hover {
    .action {
      display: block !important;
    }
  }

  .picklist-table-container {
    .wzmh-pagination {
      display: none;
    }

    table {
      border: 1px solid $grey-4;
    }

    th {
      font-size: 12px;
      text-transform: uppercase;
      text-align: center !important;
    }

    .product__name,
    .product__quantity {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;

      &-image {
        width: 32px !important;
        aspect-ratio: 1;

        svg {
          display: none;
        }
      }
    }

    .product__quantity {
      gap: 1rem;
      justify-content: center;

      .value {
        text-align: center;
        min-width: 2ch;
      }

      .action {
        color: $primary-color;
        font-size: 1.6rem;
        cursor: pointer;

        display: none;
      }
    }

    .product-remove-button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 1.4rem;

      svg {
        cursor: pointer;
        height: 1.4rem;
        fill: $grey-2;
        display: none;
      }
    }
  }
}

.picklist-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .page-action {
    display: flex;
    gap: 1rem;

    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
    }

    .back-button {
      fill: $grey-2;

      &:disabled {
        fill: $white;
      }
    }

    .next-button {
      fill: $white;

      &:disabled {
        color: $white;
      }
    }

    svg {
      width: 1em;
    }

    .invert--hr {
      transform: rotate(180deg);
    }
  }
}

.button--export {
  min-width: 10ch;
  margin-block: 0.5rem;
  font-size: small;
  text-align: center;
  justify-content: center;
  padding: 0.5rem;
}

.picklist__popover__options {
  button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem !important;
    width: 100%;
    border-radius: 0.5rem !important;

    svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: $grey-2;
    }

    span,
    &.wzmh-btn-text {
      text-decoration: none !important;
      border: none !important;
    }

    &:hover {
      background-color: transparentize(
        $color: $grey-2,
        $amount: 0.95
      ) !important;

      span,
      &.wzmh-btn-text {
        text-decoration: none !important;
        border: none !important;
      }
    }
  }
}

.picklist__popover {
  .display-flex {
    margin-block: 0.5rem;
  }

  .add-item__form__buttons {
    justify-content: space-evenly;
  }

  .edit__picklist--switch {
    &,
    .switch {
      width: 100%;
    }

    .switch__field {
      justify-content: space-between;
      border: 1px solid $border-color;
      border-radius: 0.8rem;
    }

    .wzmh-switch.wzmh-switch-checked {
      background-color: $primary-color;
    }
  }
}

.picklist-tab__dropdown__search {
  margin: 1rem 0.5rem;

  .search__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    width: 100%;

    form {
      width: 100%;
      height: 4rem;
    }

    .search__button {
      background-color: $primary-color;
      margin: 0;
      margin-right: 0.05rem;
      padding: 0.8rem;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      aspect-ratio: 1;

      svg {
        width: 1.7rem;
        height: 1.7rem;
        fill: $white;
      }
    }

    .input,
    .wzmh-input-affix-wrapper {
      height: 4rem;
      width: 100%;
      border: none !important;
    }

    input {
      height: 3.2rem;
      width: 100%;
    }
  }

  .search__bar {
    border: 1px solid $primary-light-90;
    border-radius: 10px;

    &:focus-within {
      border-color: $primary-color;

      .search__bar__field {
        svg {
          fill: $primary-color;
        }
      }
    }
  }
}

.picklist-tab__dropdown__menu {
  max-height: 24.6rem;
  overflow: scroll;

  .rc-virtual-list {
    max-height: 23rem !important;

    &-holder {
      max-height: 23rem !important;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: $scroll-bar-bg;
        }

        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-track-piece {
          background-color: transparent;
        }
      }
    }
  }
}

.picklist__status__marker {
  color: $grey-2;
  background-color: $grey-4;
  padding: 0.1rem 1rem;
  border-radius: 4rem;
  font-size: smaller;
  display: none;

  &-true {
    display: block;
  }
}

.picklist__status__marker {
  color: $grey-2;
  background-color: $grey-4;
  padding: 0.1rem 1rem;
  border-radius: 4rem;
  font-size: 1rem;
  display: flex;
  flex-direction: row;
  font-weight: 200;
  height: 2rem;
  justify-content: space-between;
  align-items: center;

  &_icon {
    max-width: 1rem;
    max-height: 1rem;
    padding-right: 0.3rem;
  }
}

.suffix-icon {
  width: 2.5rem;
  height: 2.5rem;

  &__open {
    width: 2.5rem;
    height: 2.5rem;
    transform: rotate(180deg);
  }
}

.text--right {
  text-align: right;
}

.text--center {
  text-align: center;
}

.wzmh-select-dropdown:has(.picklist-tab__dropdown__search) {
  padding: 1rem;
  padding-bottom: 0;
}

.picklist__header__selector {
  .wzmh-space-item {
    font-family: "DM Sans";
    color: $grey-6;
    font-weight: 500;
    font-size: 2.5rem;
  }
}

.wzmh-select-selection-item {
  padding-inline-end: 5rem !important;
}
