@import "src/styles/variables";

.button {
    box-sizing: border-box;
    padding-inline: 1.6rem;
    padding-block: 1.1rem 1rem;
    height: auto;
    font-size: 1.5rem;
    min-height: 3rem;
    font-family: "DM Sans";
    line-height: 2.4rem;
    border-radius: 0;
 &.small-button{
    margin-left: 2rem; 
    height: 10px;
     width: 70px; 
     padding: 0;
 }
    &.wzmh-btn-default {
        &:hover {
            border-color: $grey-3;
        }
    }

    &.wzmh-btn-link,
    &.wzmh-btn-text {
        border-radius: 0;
        cursor: pointer;
        padding-inline: 0;

        &[disabled] {
            opacity: 0.4;
        }
    }

    &.wzmh-btn-link:hover:not(.wzmh-btn-loading, [disabled]) {
        text-decoration: underline;
    }

    &.wzmh-btn-text:hover:not(.wzmh-btn-loading, [disabled]) {
        text-decoration: underline;
    }
}

.wide-button {
    padding-inline: 3.5rem;
}