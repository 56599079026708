@import 'src/styles/variables';

.prototype-container {
    margin: 2.6rem 10rem;

    &__header {
        display: flex;
        margin-bottom: 3.4rem;
        align-items: center;
        justify-content: space-between;

        .heading {
            text-align: left;
            font-family: 'DM Sans';
            font-weight: 300 !important;
            font-size: 3.5rem;
            color: $grey-6;
            opacity: 1;
            letter-spacing: 0.025em;
        }

    }

    &__add-btn {
        border-radius: 0;
        padding: 12px 32px;
        font-size: 16px;
    }

    &__content {
        transition: all 300ms ease-in-out;
        cursor: pointer;

        .wrapper-center {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .empty-container{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40vh;
            border: 0.6px solid $grey-4;
            border-radius: 12px;

        }
        &__title {
            text-align: center;
            font-family: 'DM Sans';
            font-weight: 400;
            font-size: 1.7rem;
            letter-spacing: 0px;
            color: $grey-7;
            opacity: 1;
            padding: 1.6rem 0 0.5rem 0;
            background-image: linear-gradient(120deg, #000 0%, #000 100%);
            background-repeat: no-repeat;
            background-size: 100% 0em;
            background-position: 0 88%;
            transition: background-size 0.25s ease-in;
        }

        &__card {
            padding: 0 20px !important;
        }

        .img-container {
            overflow: hidden;
            width: 100%;
            display: grid;
            transition: all 300ms ease-in-out;
            background-color: $black-transparent-10;
            border: 1px solid black;
        }

        &__img {
            width: 100%;
            object-fit: cover;
            cursor: pointer;
            transition: all 300ms ease-in-out;
        }

        .hover-animation {
            transition: all 300ms ease-in-out;

            &:hover {
                transform: translate(0, -14px);
            }
        }

        .hover-animation:hover &__img {
            transition: all 300ms ease-in-out;
            -moz-transform: scale(1.115);
            -webkit-transform: scale(1.115);
            transform: scale(1.115);
        }

        .hover-animation:hover &__title {
            font-family: 'AvenirHeavy';
            background-size: 100% 0.07em;
            transition: all 300ms ease-in-out;
        }
    }
}