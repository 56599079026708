@import "src/styles/variables";

.add-user {
    display: flex;

    &__button {
        align-items: center;
        cursor: pointer;
        display: flex;
        gap: 0.75rem;

        &__icon {
            border-radius: 100%;
            border: 1px dashed $grey-4;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.15rem 1.16rem 1.05rem 1.15rem;
            position: relative;

            .icon--add {
                position: absolute;
                inset-block-end: -0.5rem;
                inset-inline-end: 0;
                width: 1.4rem;
                aspect-ratio: 1;
            }
        }

        &--selected {
            .add-user__button__icon {
                border: 0;
                background-color: rgba($color: $primary-color, $alpha: 0.1);

                svg {
                    fill: $primary-color;
                }
            }
        }
    }
}

.add-user__popover {
    .wzmh-popover-content {
        .wzmh-popover-inner {
            padding: 0.8rem;

            .user-list__values {
                max-height: 18rem;
                overflow-y: scroll;
            }
        }
    }
}