@import "src/styles/variables";

.icon {

    #prototype,
    #product,
    #team {

        .cls-1,
        .cls-2 {
            fill: $grey-3;
        }
    }

    &--back {
        width: 1.6rem;
        height: 1rem;
    }

    &--info {
        fill: $grey-3;
        width: 1.2rem;
        height: 1.2rem;
    }


    &--hide-description {
        width: 2rem;
        height: 1.2rem;
    }


    &--view-description {
        width: 1.6rem;
        height: 0.8rem;
    }

    &--pdf-thumbnail,
    &--file-thumbnail {
        width: 3rem;
        height: 4rem;
    }

    &--down-arrow,
    &--arrow-left,
    &--arrow-right,
    &--edit,
    &--weight,
    &--width,
    &--height,
    &--length {
        height: 1.4rem;
        width: 1.4rem;
    }

    &--left-arrow,
    &--right-arrow {
        width: 0.6rem;
        height: 1rem;
    }

    &--sustainable {
        width: 1.5rem;
        height: 1.5rem;
    }

    &--inventory {
        width: 1.4rem;
        height: 1.6rem;
    }

    &--attachments {
        width: 1.4rem;
        height: 1.6rem;
    }

    &--check {
        width: 1.2rem;
        height: 0.8rem;
    }

    &--copy,
    &--user {
        width: 1.4rem;
        height: 1.6rem;
    }

    &--delete {
        width: 1.35rem;
        height: 1.5rem;
    }

    &--select {
        width: 3.5rem;
        height: 4rem;
    }

    &--user-profile {
        width: 1.5rem;
        height: 1.6rem;
    }

    &--expand-arrows-alt,
    &--remove,
    &--download,
    &--dimensions {
        height: 1.6rem;
        width: 1.6rem;
    }

    &--add {
        width: 2.4rem;
        height: 2.4rem;
    }

    &--image-upload {
        width: 3.2rem;
        height: 3.2rem;
    }

    &--blue {
        fill: $blue;
    }

    &--white {
        fill: $white;
    }

    &--danger {
        fill: $danger-color
    }

    &--primary {
        fill: $primary-color;
    }

    &--image-upload {
        width: 1.5rem;
        height: 1.5rem;
    }
}

#file_thumbnail {

    .cls-1,
    .cls-2 {
        fill: #bcc2ca;
    }
}