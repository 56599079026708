@import "src/styles/variables";

.toggle-description-icon {
    padding: 0.8rem 0.8rem 1rem 0.8rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
        &:hover {
            fill: $primary-color;
        }
    }
}