@import "src/styles/variables";
@import "src/shared/components/Pagination/pagination";

.table {
    background-color: $white;
    border-radius: 4px;

    &__header {
        align-items: center;
        display: flex;
        gap: 1rem;
        padding-inline: 1rem;

        .input {
            border-right: 1px solid $grey-3;
            flex-grow: 1;
        }

        .pagination {
            margin-inline-start: auto;
            flex-grow: 2;
            text-align: end;
        }
    }
    .table-container{
        margin-top: 1.2rem;
        .wzmh-table-wrapper .wzmh-table {
                border: 1px solid $grey-4;
                border-radius: 8px 8px 0 0;
            }
        
            .wzmh-table-wrapper table {
                border-spacing: 0.8px;
            }
    }
    
    .wzmh-table {
        &-thead {
            text-transform: uppercase;
            font-size: 1.2rem;

            tr {

                th,
                td {
                    background-color: $grouped-input-label-bg;
                    color: $grey-2;
                    letter-spacing: 0.12rem;

                    &::before {
                        display: none;
                    }
                }
            }
        }

        &-thead {
            .wzmh-table-cell {
                text-transform: uppercase;
                font-weight: normal;
                letter-spacing: 0.12rem;
            }
        }


        &-tbody {
            tr.wzmh-table-row {
                max-height: 4.8rem;

                td.wzmh-table-cell {
                    height: 4.8rem;
                }

                &:hover {
                    background-color: $white;

                    td {
                        background-color: $white;
                    }
                }
            }

            .wzmh-table-cell {
                color: $grey;
                padding: 0.8rem 1.6rem 0.8rem 1.6rem;
            }

            .editable-cell {
                padding: 0;

                &:has(input:focus) {
                    border: 2px solid $primary-color !important;
                }

            }
        }
    }

    .wzmh-table-pagination {
        @extend .pagination;
        background-color: $secondary-bg;
        margin-block: 0 !important;
        padding-block: 1.6rem;
    }
}