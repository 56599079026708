@import "src/styles/variables";

.view-product-content {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid $black;
    // margin-bottom: 2.4rem;

    &-info {
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    font-size: 2.4rem;
    margin-bottom: 0.8rem;
  }

  &__body {
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 3.2rem;
  }

  &__left-column {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    background-color: #f9f9f9;
    padding: 2.4rem;
  }

  &__right-column {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  &__image-container {
    position: relative;
    border-radius: 16px;
    border: 1px solid $grey-5;
    overflow: hidden;
    width: 100%;
    max-width: 400px;
    aspect-ratio: 1;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__image-overlay {
    color: $white;
    fill: $white;
    cursor: pointer;
    box-sizing: border-box;
    height: 4rem;
    border-radius: 10px;
    background: $primary-color;
    display: flex;
    overflow: hidden;

    justify-content: space-around;
    align-items: flex-start;

    position: absolute;
    bottom: 2rem;
    left: 2rem;

    padding: 1rem;
    transition: all ease-in-out 0.3s;

    svg {
      height: 2rem;
    }

    &_text {
      height: 1.8rem;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 0;
      display: inline-block;
      padding-left: 0;
      transition: width ease-in-out 0.3s, opacity ease-in-out 0.3s 0.1s,
        padding ease-in-out 0.3s;
      opacity: 0;
    }

    &:hover {
      .view-product-content__image-overlay_text {
        padding-left: 0.5rem;
        opacity: 1;
        width: 9.5rem;
      }
    }
  }

  &__attachments {
    margin-top: 0.8rem;
  }

  .product-description {
    .wzmh-collapse-content-box {
      white-space: pre-line;
      gap: 0.5rem;
    }
  }
}
