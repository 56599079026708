@import "src/styles/variables";

.plan-view {
    padding: 1.6rem 0.6rem;
    padding-bottom: 0;
    overflow: hidden;

    &__container {
        display: flex;
        width: 100%;
        height: calc(100vh - ($navbar-height + 2.4rem));
        gap: 2rem;

        &__left {
            background-color: $white;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
            width: 46.6rem;
            overflow-y: scroll;
            padding: 2rem 2.4rem;
        }

        .wzmh-breadcrumb {
            li:last-child {
                color: $grey-2;
            }
        }
    }
}