@import 'src/styles/variables';

.diagram-view-outer {
    position: relative;

    .background-overlay {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        color: $white;
        background-color: $black;
        opacity: 0.7;
    }

    .text-overlay {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: $white;
        z-index: 12;
    }

    .diagram-view {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .transform-component-module_wrapper__SPB86 {
            height: calc(100vh - $navbar-height);
            width: auto;
        }
    }

    &:hover .background-overlay {
        display: none;
    }

    &:hover .text-overlay {
        display: none;
    }

    &.cursor-prototype:hover .diagram-view-pin-cursor {
        cursor: url(../../../../../src/assets/icons/cursor/map-space-mouse-pointer.png), auto;
    }

    &.cursor-space:hover .diagram-view-pin-cursor {
        cursor: url(../../../../../src/assets/icons/cursor/map-product-mouse-pointer.png), auto;
    }
}

.diagram-view__text-overlay {
    background-color: transparentize($color: $grey, $amount: 0.2);
    color: $white;
    display: block;
    width: fit-content;
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 10;
    font-family: "DM Sans";
    font-weight: 300;
}

.dual-view-icons__icon-gp {
    display: flex;
    &__zoom_icon {
        display: block;
        width: fit-content;
        position: absolute;
        top: 2rem;
        right: 6rem;
        z-index: 10;
        border-radius: 16px; // Equal horizontal and vertical radii
        background-color: white;
        border: 1px solid $grey-4;
        height: 3.4rem;
        width: 8rem;
        display: flex;
        padding-inline: 1rem;
        justify-content: space-between;
        align-items: center;
        margin-left: 1.4rem;
        cursor: pointer;

        .icon {
            fill: $grey-2;
            height: 1.2rem;
            width: 1.2rem;
        }

        &:hover .icon {
            fill: $primary-color
        }
    }

    &__icon {
        // border-radius: 8px;
        border: 1px solid $primary-color;
        height: 3.2rem;
        width: 3.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 1.4rem;
        cursor: pointer;

        .icon {
            fill: $grey-2;
            height: 1.2rem;
            width: 1.2rem;
        }

        &:hover .icon {
            fill: $primary-color
        }
    }
}
.diagram-view__image__action {
    display: block;
    width: fit-content;
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 10;
    border-radius: 50% !important;
    background-color: white;


    &.dual-view-icons__icon-gp {
        display: flex;

        &__icon {
            border-radius: 8px;
            border: 1px solid $grey-4;
            height: 3.2rem;
            width: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 1.4rem;
            cursor: pointer;

            .icon,
            svg {
                fill: $grey-2;
                height: 1.2rem;
                width: 1.2rem;
            }

            &:hover .icon,
            &:hover svg {
                fill: $primary-color
            }
        }
    }
}

.pointer-all .image-marker__image {
    pointer-events: all;
}

.display-none {
    display: none;
}