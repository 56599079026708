@import "src/styles/variables";

.space-list {
    .folder-header {
        padding-inline: 0;
    }

    .folder-view {
        margin-inline: -2.4rem;

        &__item {
            padding-inline: 2.4rem;
            text-transform: capitalize;
        }
    }
}