.space-type-gallery {
  margin-top: 2.4rem;
  display: flex;
  column-gap: 1.6rem;
  row-gap: 1.6rem;
  flex-wrap: wrap;
  max-width: 100ch;

  &__item {
    height: 15rem;
    width: 20rem;
    max-height: 15rem;
    max-width: 20rem;
    border-radius: 0.4rem;
    aspect-ratio: 4/3;
  }

  .uploaded-image {
    width: 100%;
    aspect-ratio: 4/3;
    display: flex;
    justify-content: center;
    align-items: center;

    &__container {
      width: 100%;
      aspect-ratio: 4/3;
    }

    &__content {
      width: 100%;
      height: auto;
      aspect-ratio: auto;
    }
  }

  .image-preview__content {
    img {
      width: auto !important;
      height: auto !important;
      max-height: 80vh;
      aspect-ratio: auto !important;
    }
  }
}

.space-type-gallery .upload-input .uppy-DragDrop-container {
  aspect-ratio: 4/3;
}
