@import "src/styles/variables";

.user-list {
    display: flex;
    flex-direction: column;

    &__values {
        display: flex;
        flex-direction: column;

        &__item {
            cursor: pointer;
            padding: 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .icon--check {
                display: none;
            }

            &:hover {
                background-color: $grey-5;
            }

            &--active {
                font-family: "AvenirHeavy";

                .icon--check {
                    display: inline;
                    fill: $primary-color;
                }
            }
        }
    }
}