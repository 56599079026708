@import "src/styles/variables";

.user-mgmt{
    padding: 3.2rem;
    &__heading{
        font-size: 2.5rem;
        font-family: 'DM Sans';
        color: $grey-6;
        font-weight: 500;
    }
    .icon-edit{
        width: 1.4rem;
        
        .cls-1 {
          fill: $grey-6 !important;
          stroke-width: 0px;
        }

        .cls-2 {
          fill: none !important;
          stroke: white !important;
          stroke-miterlimit: 10;
          stroke-width: 0.6px;
        }

        &:hover {
          cursor: pointer;
          .cls-1 {
            fill: $primary-color !important;
            stroke-width: 0px;
          }
        }
    }
}

.picklist-footer {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
    align-items: center;
  
    .page-action {
      display: flex;
      gap: 1rem;
  
      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
      }
  
      .back-button {
        fill: $grey-2;
  
        &:disabled {
          fill: $white;
        }
      }
  
      .next-button {
        fill: $white;
  
        &:disabled {
          color: $white;
        }
      }
  
      svg {
        width: 1em;
      }
  
      .invert--hr {
        transform: rotate(180deg);
      }
    }
  }