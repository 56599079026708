@import "src/styles/variables";

.activity-container {
    padding-top: 1.7rem;

    .activity-container__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'AvenirMedium';
        margin-bottom: 2.4rem;

        .activity-container__heading__left {
            font-size: 1.2rem;
            color: $grey-2;
            letter-spacing: 1.2px;
        }

        .activity-container__heading__right {
            font-size: 1.5rem;
            color: $grey;
        }
    }

    .activity-logs {
        margin-top: 0.4rem;
        max-height: calc(100vh - $navbar-height - 35rem);
    }

    .icon-activity {
        height: 1.5rem;
        width: 1.5rem;
        fill-rule: evenodd;
    }

    .wzmh-steps-item-title{
        width: 100%;
    }
}