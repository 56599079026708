@import "src/styles/variables";

.marker {
  &__outer {
    height: 2.4rem;
    width: 2.4rem;
    border: 1px solid $white;
    background-color: $primary-color;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.color-black {
      background-color: $black;

      z-index: 1 !important;
      top: 0 !important;
    }
  }
  .icon-pin {
    fill: $white;
    height: 1.2rem;
  }
  img {
    pointer-events: all;
  }
  .icon-eye-hover {
    display: none;
  }
}

.marker__outer:hover {
  .icon-pin {
    display: none;
  }
  .icon-eye-hover {
    display: inline;
  }
}
