@import "src/styles/variables";

.category-navbar {
  width: calc(100vw - 15.8rem);
  display: flex;
  justify-content: space-between;
  padding: 2.9rem 5.9rem 0.3rem 9.9rem;
  background-color: $grey-5;
  overflow: hidden;
  margin-bottom: 5.379rem;
  &__content {
    &__product-count {
      color: $primary-color;
      margin-bottom: 2rem;
      text-align: left;
      font-family: "DM Sans";
      font-weight: bold;
      font-size: 2.6rem;
      letter-spacing: 0;
      cursor: pointer;
    }
    &__name {
      margin-right: 3.74rem;
      margin-bottom: 2.3rem;

      a {
        font-family: "DM Sans";
        font-weight: 300;
        color: $grey-11 !important;
      }
    }
  }
  &__image {
    margin-top: -50px;
    overflow: visible;
    flex: 1;
  }
}
@media (max-width: 1024px) {
  .category-navbar {
    padding: 2.9rem 2.8rem 0.3rem 4rem;
    width: calc(100vw - 6.8rem);
  }
}
@media (max-width: 844px) {
  .category-navbar {
    &__image {
      display: none;
    }
  }
}
