@import "src/styles/variables";

.configurations {
    padding: 2.4rem 7.8rem;
    height: 100%;

    .folder-header {
        border-block-end: 1px solid $border-color;
    }
}

.icon-settings-enabled {

    .cls-settings-2,
    .cls-settings-3 {
        fill: $primary-color;
    }

    .cls-1 {
        stroke: $primary-color !important;
    }
}