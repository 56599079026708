@import "src/styles/variables";

.breadcrumb {
    .wzmh-breadcrumb {
        ol {
            align-items: center;
        }
    }

    .wzmh-breadcrumb-separator {
        display: flex;
    }
    &--truncate{ 
        .wzmh-breadcrumb-link{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 10.1rem;
            display: flex;
            align-items: center;
        }
        .wzmh-breadcrumb{
            li{
                display: flex;
                align-items: center;
            }
        }
    }

    &--lg {
        .wzmh-breadcrumb-link {
            font-size: 1.8rem;
            font-family: "AvenirHeavy";
            display: flex;
            align-items: center;
        }



        .icon--right-arrow {
            width: 0.7rem;
            height: 1.2rem;
        }
    }

    &--sm {
        .icon--right-arrow {
            height: 1.2rem;
            fill: $grey-4
        }

        .wzmh-breadcrumb-link {
            font-size: 1.2rem;
            font-family: "AvenirMedium";
            text-align: left;
            color: $black-light-10;
            display: flex;
            align-items: center;
        }
    }
}

