@import "src/styles/variables";

.space-type-image-marker {
  position: relative;
  margin: 0 auto;
  height: 100%;
  width: 100%;

  &-loader {
    height: calc(100vh - 5.6rem - $navbar-height) !important;
  }
}

.react-transform-wrapper {
  min-height: calc(100vh - 5.6rem - $navbar-height) !important;
}

.react-transform-component {
  width: 100% !important;
  justify-content: center;
  align-items: stretch;
}

.space-type-image-marker {
  &__loader {
    width: 100%;
    min-height: calc(100vh - 5.6rem) !important;

    .lazy-loader__container__loader {
      min-height: calc(100vh - 5.6rem) !important;
    }
  }

  &__image {
    pointer-events: all !important;
  }
}

.space-type-image-marker__marker {
  position: absolute;
}
