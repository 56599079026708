.attachments {
  .wzmh-collapse-content-box {
    display: grid;
    grid-template-columns: repeat(auto-fill, 160px);
    gap: 1rem;
  }

  &--no-collapse {
    .attachments__content {
      display: grid;
      grid-template-columns: repeat(auto-fill, 160px);
      gap: 1rem;
      margin-top: 1rem;
    }
  }
}
