@import "src/styles/variables";

.multiple-variant {
  border-radius: 8px;
  border: 1px solid $grey-4;
  overflow: hidden;

  .table {
    border: 0;

    table {
      table-layout: auto;
    }

    &__header {
      display: none;
    }

    .editable-cell {
      padding: 0;
    }

    .wzmh-table-wrapper
      .wzmh-table.wzmh-table-bordered
      > .wzmh-table-container {
      border-inline-start: 0;

      .wzmh-table-cell:last-child {
        border-inline-end: 0;
      }
    }

    .wzmh-table-tbody .wzmh-table-cell {
      padding: 0 1.6rem;
      min-height: 4.8rem;

      .wzmh-input {
        border: none;
        padding: 0.8rem 0;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .multiple-variant__image {
      padding-block: 0.8rem;

      &__container {
        display: flex;
        justify-content: center;
      }

      .uploaded-image {
        &:hover {
          .uploaded-image__overlay {
            background-color: rgba($color: $grey, $alpha: 0.9);
          }
        }
      }

      .uppy-DragDrop-container {
        padding: 0.85rem;
      }

      .uppy-DragDrop-inner {
        display: flex;

        &::before {
          width: 1.5rem;
          aspect-ratio: 1/1;
          margin-bottom: 0;
          line-height: 0;
          height: 1.5rem;
        }
      }
    }
  }

  .variant-image {
    width: 3.2rem;
    height: 3.2rem;
  }

  .variant-image-icon {
    border: 1px dashed $border-color;
    border-radius: 4;
    padding: 0.85rem;
  }

  &__footer {
    padding: 0.6rem 1.6rem;

    .button.wzmh-btn-link {
      padding: 0;
    }
  }

  .upload-input,
  .attachment-upload-loader {
    width: 3.6rem;
    aspect-ratio: 1 !important;

    button {
      width: 3.6rem;
      height: 3.6rem;
      aspect-ratio: 1 !important;
    }
  }

  .multiple-variant__image__container {
    padding: 0.4rem;

    .uploaded-image__container,
    .uploaded-image,
    .lazy-loader__container__image {
      width: 3.6rem;
      height: 3.6rem;
      aspect-ratio: 1 !important;
    }
  }
}
