@import "src/styles/variables";

.status {
    padding: 0.4rem 1rem;
    border-radius: 18px;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 1.2rem;
    font-family: "AvenirMedium";

    &--sustainable,
    &--active {
        background-color: rgba($green, 0.1);
        color: $green;
    }

    &--unsustainable {
        color: $grey-2;
        background-color: rgba($color: $grey-2, $alpha: 0.25)
    }

    &--in-active {
        background-color: rgba($danger-color, 0.1);
        color: $danger-color;
    }
}