@import "src/styles/variables";

.input {
    display: flex;
    flex-direction: column;
    gap: 7px;

    .search-icon {
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 1.6rem;
    }

    input {
        padding: 1.2rem 1.6rem;
        height: 4.4rem;

        &:hover,
        &:focus {
            border-width: 2px;
        }

        &::placeholder {
            opacity: 0.4;
        }
    }

    .search-input {
        input {
            padding-top: 0;
            height: 3rem;
            width: 26rem;
        }
    }

    .close-icon {
        cursor: pointer;
        display: none;
        height: 1.4rem;
        width: 1.4rem;
        padding: 0.4rem;
        border-radius: 4px;

        &:hover {
            background: $primary-light-80;
        }
    }

    .wzmh-input-affix-wrapper-focused {
        .close-icon {
            display: block;
        }
    }
}