@import "src/styles/variables";

.activity-notifications-header-extra {
    position: relative;
    margin-left: 1.6rem;
    cursor: pointer;

    .activity-notifications-container {
        cursor: pointer;
        display: flex;
        padding: 1rem 1.6rem;
        align-items: center;
        background: $white 0 0 no-repeat padding-box;
        height: 2rem;

        &__text {
            text-align: left;
            letter-spacing: 0;
            color: $black-light-10;
            margin-left: 1.2rem;
        }

        svg {
            fill: $black-light-40;
        }
    }

    .filtered {
        position: absolute;
        inset-inline-end: -3px;
        inset-block-start: 0;
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 100%;
        background: $blue;
    }
}

.activity-notifications-filters {

    .wzmh-drawer-content-wrapper {
        min-width: 56.5rem;
    }
}

.icon {
    height: 1.5rem;
    width: 1.5rem;
    fill: $grey-3;
}

.row {
    display: flex;
    align-items: center;
    gap: 0.625rem;


}