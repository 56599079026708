@import "src/styles/variables";

.product-header-extra {
    position: relative;
    margin-left: 1.6rem;
    .filter-container{
        cursor: pointer;
        display: flex;
        padding: 1rem 1.6rem;
        align-items: center;
        background: $white 0 0 no-repeat padding-box;
        border: 1px solid $primary-color;
        width: 6.9rem;
        height: 2rem;
        &__text{
            text-align: left;
            letter-spacing: 0;
            color: $black-light-10;
            margin-left: 1.2rem;
        }
        svg{
            fill: $black-light-40;
        }
    }
    .filtered {
        position: absolute;
        inset-inline-end: -3px;
        inset-block-start: 0;
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 100%;
        background: $blue;
    }
}

.product-filters {

    .wzmh-drawer-content-wrapper {
        min-width: 56.5rem;
    }
}