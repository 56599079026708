/* Color palette */
$primary-black: #273239;
$green: #24b587;
$yellow: #ffb03c;
$danger-color: #ff4349;
$line-separator: #eff2f6;
$secondary-color: #006FBA;
$light-white: #FFFFFF40;
$primary-light-color: #0D494E10;
$dark-blue: #22509D;
$light-grey: #00000015;
$dark-grey: #8898AA;
$success-color: green;
$warning-color: #F3B21B;
$body-bg: #F5F8FA;
$table-bg: #F7FAFC;
$input-bg: #F8FBFC;
$border-color: #d6dbeb;
$secondary-bg: #f8f8f8;
$navbar-height: 13rem;
$navbar-topbar-height: 20px;
$prototype-view-height: calc(100vh - $navbar-height);
$black: #000;
$black-light-10: #221E1E;
$black-light-40 :#4F4A4A;
$black-transparent: #7090B01F;
$black-transparent-10: #06142B0A;
$sustainable: #f7fcf4;
$attachment-bg: #f4f5fa;
$grouped-input-label-bg: #F7F7F8;
$add-icon-white: #f5f6f7;

// Primary Color
$primary-color: #FF3C00;
$primary-dark-10: #C93000;
$primary-dark-20: #B22A00;
$primary-dark-40: #862000;
$primary-dark-60: #591500;

$primary-light-10: #E2491A;
$primary-light-30: #E9724D;
$primary-light-50: #EF9A80;
$primary-light-60: #F2AE99;
$primary-light-70: #F5C2B3;
$primary-light-80: #FFF0EA;
$primary-light-90: #D1D1D6;
$primary-light-100:#F5C2B3;

// Blue
$blue: #3d6fe6;

$blue-dark-20: #3159b8;
$blue-dark-40: #25438a;
$blue-dark-60: #182c5c;

$blue-light-20: #648ceb;
$blue-light-40: #8ba9f0;
$blue-light-60: #b1c5f5;

// Danger
$danger: #ff4349;

$danger-dark-10: #e63c42;

$danger-light-20: #ff696d;
$danger-light-60: #ffb4b6;
$primary-light-60: #FDF3F0;
$primary-light-110 :#FFEFEA;

// Grey
$grey: #221e1e;
$grey-2: #4f4a4a;
$grey-3: #939393;
$grey-4: #d1d1d6;
$grey-5: #f4f4f4;
$grey-6: #5F5F5F;
$grey-7: #595959;
$grey-8: #6d6e71;
$grey-9: #f7f7f7;
$grey-10: #919496;
$grey-11: #878787;
$grey-12: #e3e4e5;
$grey-13: #F4F5F5;

$attachment-bg: #f4f5fa;
$sustainable: #f7fcf4;

$secondary-button-color: #fafcff;
$active-step: #33414a;

// NAVBAR
$navbar-background: #FFFFFF;
$add-product-stepper-bg: #fffaf4;

$login-logo-box-shadow-color: #ef89330f;

// General
$white: #ffffff;

// Attachment
$attachment-primary-bg: #FEF9F5;

// Sustainability
$sustainable-product: #4DAB71;
$sustainable-product-light: #66A976;
$success-dark-10: #20A37A;
$sustainable-cls-1: #c3ebb0;
$circle-avatar-color: #C44932;

$transparent-black-20: #06142B0A;
$pin-marker-color: #DF3500;
$background_green: #A7E1CF;
$icon-background-green: #a7e1cf4d;
$icon-background-grey: #d1d1d64d;

// Diagram view
$diagram-view-height: calc(100vh - $navbar-height);
$notification-popup-height: calc(60vh);
$scroll-bar-bg: #00000030;
$scroll-bar-shadow: #00000030;
