@import "src/styles/variables";

.product-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-container {
        font-size: 1.6rem;
        gap: 1rem;
    }

    .icon--arrow-left {
        fill: $grey;
    }

    &__end {
        display: flex;
        gap: 1.6rem;
    }
}