@import "src/styles/variables";

.folder-view {
    height: 100%;

    $inline-padding: 1.6rem;

    .wzmh-skeleton {
        padding-inline: $inline-padding;
    }

    &__item {
        cursor: pointer;
        padding: 1.1rem $inline-padding;
        display: flex;
        justify-content: space-between;

        &:hover:not(&--active) {
            background-color: rgba($color: $grey-2, $alpha: 0.05);
        }

        &--active {
            background-color: rgba($color: $primary-color, $alpha: 0.1);
            border-inline-start: 2px solid $primary-color;

            .folder-view__item__right {
                .rounded-arrow {
                    background-color: rgba($color: $primary-color, $alpha: 0.1);

                    .icon--right-arrow {
                        fill: $primary-color;
                    }
                }
            }
        }

        &--active-secondary {
            background-color: rgba($color: $grey-2, $alpha: 0.1);
            border-inline-start: 2px solid $grey-2;
        }

        &__left {
            flex-grow: 1;
        }
    }
}