@import "src/styles/variables";

.card-container {
  background-color: $white;
  margin-bottom: 1.6rem;
  transition: all 0.3s ease-in-out;
  max-width: 100%;
  padding: 0.5rem;

  &:hover {
    transform: translateY(-0.2rem);
  }

  &:first-child {
    margin-top: 0.5rem;
  }

  .plan-or-layer-card {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      .icon {
        opacity: 1 !important;
      }
    }

    &__details {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &__image {
      width: 50px;
      height: 50px !important;
    }

    .aspect-1 {
      aspect-ratio: 1;
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .dual-view-icons__icon-gp {
        display: flex;

        &__icon {
          border-radius: 8px;
          height: 3.2rem;
          width: 3.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 1.4rem;
          border: none;
          cursor: pointer;

          .icon {
            fill: $grey-2;
            height: 1.2rem;
            width: 1.2rem;
            opacity: 0.4;
          }

          &:hover .icon {
            fill: $primary-color;
          }

          &.icon-hidden {
            display: none;
          }
        }
      }
    }

    img {
      border-radius: 8px;
    }

    &__title {
      margin-left: 1.4rem;
      font-family: "DM Sans";
    }

    &.expanded {
      // background: $primary-light-110;
      // border-bottom: 0.4px solid $grey-8;
      .icon--edit {
        opacity: 1 !important;
      }
    }
  }

  .space-card {
    padding: 1rem;

    .disp {
      display: flex;
      align-items: center;
      padding-left: 1.8rem;
      margin-bottom: 1.2rem;
    }

    .space-heading {
      color: $grey-3;
      font-size: 1.2rem;
      padding-right: 0.8rem;
      cursor: pointer;
    }

    .icon-add {
      fill: $primary-color;
      height: 1.4rem;
      cursor: pointer;
    }

    .space-list {
      padding: 1.1rem 1.1rem 1.1rem 1.8rem;
      border-radius: 8px;

      // &.active-space {
      //     background-color: $transparent-black-20;
      // }

      &:hover {
        // background-color: $transparent-black-20;
        cursor: pointer;
      }

      .icon-pin {
        height: 1.6rem;
        width: 1.6rem;
        fill: $black-light-40;
        margin-left: 3.2rem;
      }

      .icon-pin:hover {
        fill: $grey;
      }

      span {
        font-family: "DM Sans";
        font-size: 1.5rem;
      }
    }
  }
}

.dual-view__left-container__add-sub-view__wrapper {
  margin-top: 1.2rem;
}

.icon--accordion {
  fill: none !important;
  stroke: $primary-color !important;
  width: 80px !important;
  height: 80px !important;
}

.icon--accordion--closed {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  fill: none !important;
  stroke: $primary-color !important;
  width: 80px !important;
  height: 80px !important;
}

.icon--edit {
  width: 80px !important;
  height: 80px !important;
  margin-right: 8px;
}

.expandable-card__zone-submenu {
  .wzmh-menu-submenu-title {
    height: 60px;

    &:hover {
      border-radius: 0;
    }

    .wzmh-menu-title-content {
      font-family: "DM Sans";
      font-size: 1.8rem;
      font-weight: 500;
    }

    // i {
    //   display: none;
    // }
  }

  .wzmh-menu-submenu-title[aria-expanded="true"] {
    background-color: $grey-13;
    border-radius: 0 !important;
  }

  ul.wzmh-menu-sub {
    background-color: white !important;
  }

  .wzmh-menu-item-selected {
    background-color: $grey-12 !important;
    border-radius: 0;
  }

  .active-space {
    color: black !important;

    span {
      font-weight: 600 !important;
    }
  }


}

.wzmh-menu-submenu-open {
  border-bottom: 1px solid #6D6E71 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding-bottom: 20px !important;
  margin-bottom: 20px !important;
}

.plan-or-layer-card__title {
  color: $grey-6;
  font-weight: 500;
}

.edit-icon {
  .cls-1 {
    fill: $primary-color !important;
  }
}
