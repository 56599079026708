@import "src/styles/variables";

.collapse {
    .wzmh-collapse-item {
        .wzmh-collapse-header {
            flex-direction: row-reverse;
            padding-inline: 0;
            font-size: 1.8rem;
            font-family: "AvenirHeavy";
        }

        .wzmh-collapse-content {
            .wzmh-collapse-content-box {
                padding: 0;
            }
        }
    }
}