@import "src/styles/variables";

.space-type-grouped-pin {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &__count {
    position: absolute;
    top: -1rem;
    right: -0.5rem;
    background-color: $white;
    border-radius: 50%;
    height: 1.6rem;
    width: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    color: $black;
    border: 1px solid $primary-color;
  }

  .icon-pin {
    fill: $white;
    height: 1.2rem;
  }
}
