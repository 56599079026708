@import "src/styles/variables";

.view-product-header {
    display: flex;
    justify-content: space-between;

    .view-product-header__back {
        color: $grey-2;
        font-size: 1.4rem;
        position: sticky;
        left: 0;
        top: 11.6rem;

        &__copy {
            display: flex;
            align-items: center;

            &:hover {
                svg {
                    fill: $primary-color
                }
            }
        }
    }

    &__end {
        display: flex;
        gap: 2.5rem;
    }
}