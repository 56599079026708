@import "src/styles/variables";

.navbar {
  background-color: $navbar-background;

  position: fixed;
  inset: 0;
  inset-block-end: auto;

  z-index: 10;
  height: $navbar-height;
  border-bottom: 1px solid $grey-10;

  &__content {
    align-items: center;
    font-size: 1.5rem;
    padding-inline: 3.4rem;
    display: grid;
    grid-template-columns: 2fr 4fr 1fr;
    height: calc(100% - $navbar-topbar-height);
  }

  &__top-bar {
    height: $navbar-topbar-height;
    width: 100%;
    background-color: $primary-color;
  }

  &__logo {
    width: auto;
    height: 60px;
    object-fit: cover;
    object-position: center;
  }

  .icon-wrapper {
    padding: 1.2rem 0;
    border-radius: 8px;
  }

  .navbar__menu {
    background-color: $navbar-background;
    color: $grey-7;
    align-items: center;
    border-bottom: none;
    height: 100%;
    justify-self: flex-end;

    .wzmh-menu-item {
      cursor: pointer;
      line-height: 1.714;
      padding-inline: 0;
      height: 100%;
      margin-right: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover,
      &.wzmh-menu-item-active,
      &.wzmh-menu-item-selected {
        color: $primary-color;
        border-bottom: 2px solid $primary-color;

        &:hover {
          color: $primary-color !important;
        }
      }
    }

    &__item {
      display: flex;
      gap: 0.5rem;
      padding: 0.8rem 3.2rem;
      font-size: large;
      font-weight: 300;
      font-family: "DM Sans";
    }
  }

  .navbar__right {
    display: flex;
    gap: 5rem;
    align-items: center;
    margin-left: auto;
    justify-content: center;
    align-items: center;

    .icon {
      cursor: pointer;
      fill: $grey-5;

      svg {
        height: 1.6rem;
        aspect-ratio: 1;
        cursor: pointer;

        &:hover {
          .cls-1 {
            stroke: $primary-color !important;
          }
        }
      }

      &:hover {
        fill: $primary-color;

        .cls-settings-2,
        .cls-settings-3 {
          fill: $primary-color;
        }
      }

      .cls-1:hover {
        stroke: $primary-color !important;
      }

      .active {
        fill: $primary-color !important;

        .cls-1 {
          stroke: $primary-color !important;
        }
      }
    }
  }

  .icon--user {
    height: 2.5rem !important;
    width: 2.5rem !important;

    background-image: url("../../../assets/icons/solid/user-profile.svg");
    background-repeat: no-repeat;
    background-size: cover;

    &:hover {
      background-image: url("../../../assets/icons/solid/user-profile-enabled.svg");
      cursor: pointer;
    }

    &__active {
      background-image: url("../../../assets/icons/solid/user-profile-enabled.svg");
    }
  }

  .icon-notify {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }

  .icon--settings {
    height: 2.5rem !important;
    width: 2.5rem !important;
    display: block;

    background-image: url("../../../assets/icons/multicolored/settings.svg");
    background-repeat: no-repeat;
    background-size: cover;

    &:hover {
      background-image: url("../../../assets/icons/multicolored/settings-enabled.svg");
      cursor: pointer;
    }

    &-enabled {
      span {
        background-image: url("../../../assets/icons/multicolored/settings-enabled.svg");
      }
    }
  }
}

@media (max-width: 1024px) {
  .navbar {
    &__content {
      padding-inline: 2rem;
      grid-template-columns: 1fr 3fr 1fr;
    }

    .navbar__menu {
      .wzmh-menu-item {
        margin-right: 2rem;
      }

      &__item {
        padding: 0.8rem 1.6rem;
      }
    }

    .navbar__right {
      gap: 2rem;
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    &__content {
      grid-template-columns: 1fr 2fr 1fr;
    }

    .navbar__menu {
      .wzmh-menu-item {
        margin-right: 1rem;
      }

      &__item {
        padding: 0.8rem 1rem;
        font-size: medium;
      }
    }

    .navbar__right {
      gap: 1.5rem;
    }
  }
}

.user-profile-popover {
  &__root {
    position: fixed;

    .wzmh-popover-content {
      margin-right: 1.6rem !important;
    }
  }

  &__container {
    width: auto;
    color: $grey;
    padding: 1rem;
    min-width: 24rem;
  }

  &__demographic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    &--icon {
      padding: 1.4rem;
      background-color: $primary-light-60;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      svg {
        width: 2.4rem;
        height: 2.4rem;
        fill: $primary-color;
      }
    }
  }

  &__name {
    font-size: 1.6rem;
    font-family: "AvenirHeavy", sans-serif;
  }

  &__role {
    font-family: "AvenirBook", sans-serif;
  }

  &__contact {
    padding: 2.4rem 0;
    color: $grey-2;

    svg {
      width: 1.4rem;
      fill: $grey-3 !important;
    }
  }

  &__email,
  &__phone {
    padding-block: 0.6rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }

  &__actions {
    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }

    .button-logout {
      svg {
        width: 1.8rem;
        height: 1.8rem;
        fill: $grey-2;
      }
    }
  }
}

.notification-icon {
  background-image: url("../../../assets/icons/solid/notification.svg");
  width: 2.5rem;
  height: 2.5rem;

  &:hover {
    cursor: pointer;
    background-image: url("../../../assets/icons/solid/notification_enabled.svg");
  }

  &__active {
    background-image: url("../../../assets/icons/solid/notification_enabled.svg");
  }
}
