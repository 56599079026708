@import 'src/styles/variables';
.no-access{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(70vh - $navbar-height);
    width: 100vw;
    &__img{
        display: flex;
        justify-content: center;
    }
    &__title{
        font-family: 'AvenirBlack';
        font-size: 2.4rem;
        color: $grey;
        margin-bottom: 1.5rem;
        text-align: center;
    }
    &__descr{
        font-family: 'AvenirMedium';
        font-size: 1.6rem;
        color: $primary-black;
        text-align: center;
    }
}