@import "src/styles/variables";

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background-color: $circle-avatar-color;
  color: white;
  font-size: 1.5rem;
}
.image-view{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 10rem;
  overflow: hidden; 
  border-radius: 50%;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7); // Background color with transparency
  z-index: 1; // Ensure it's above the image
}

.image-container {
  position: relative; // Create a stacking context for the loader
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  overflow: hidden;

  // Style the image to fill the container
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.row {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  align-items: flex-start;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: $circle-avatar-color;
}

.name {
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 800;
  padding: 1rem;
  text-transform: uppercase;
  white-space: nowrap;   
  overflow: hidden;     
  text-overflow: ellipsis; 
}
