@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";
@import "helpers";
@import "@antOverrides";

/* General component styles */
@font-face {
  font-family: "AvenirRegular";
  src: url("../assets/fonts/avenir-regular.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirBlack";
  src: url("../assets/fonts/avenir-black.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirBook";
  src: url("../assets/fonts/avenir-book.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirHeavy";
  src: url("../assets/fonts/avenir-heavy.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirLight";
  src: url("../assets/fonts/avenir-light.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirMedium";
  src: url("../assets/fonts/avenir-medium.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayBlack";
  src: url("../assets/fonts/Raleway-Black.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayBlackItalic";
  src: url("../assets/fonts/Raleway-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayBold";
  src: url("../assets/fonts/Raleway-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayBoldItalic";
  src: url("../assets/fonts/Raleway-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayExtraBold";
  src: url("../assets/fonts/Raleway-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayExtraBoldItalic";
  src: url("../assets/fonts/Raleway-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayExtraLight";
  src: url("../assets/fonts/Raleway-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayVariableFont";
  src: url("../assets/fonts/Raleway-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayItalicVariableFont";
  src: url("../assets/fonts/Raleway-Italic-VariableFont_wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "RalewayItalic";
  src: url("../assets/fonts/Raleway-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayLight";
  src: url("../assets/fonts/Raleway-Light.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayLightItalic";
  src: url("../assets/fonts/Raleway-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayMedium";
  src: url("../assets/fonts/Raleway-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayMediumItalic";
  src: url("../assets/fonts/Raleway-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayRegular";
  src: url("../assets/fonts/Raleway-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "RalewaySemiBold";
  src: url("../assets/fonts/Raleway-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "RalewaySemiBoldItalic";
  src: url("../assets/fonts/Raleway-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayThin";
  src: url("../assets/fonts/Raleway-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "RalewayThinItalic";
  src: url("../assets/fonts/Raleway-ThinItalic.ttf") format("truetype");
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  color: $primary-black;
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  font-family: "DM Sans", sans-serif;
  line-height: 1.714;
  margin: 0;
}

h1,
h2 {
  font-family: "DM Sans", sans-serif;
}

h3,
h4,
h5 {
  font-family: "DM Sans", sans-serif;
}

h1 {
  font-size: 3.2rem;
}

h2 {
  font-size: 2.8rem;
}

h3 {
  font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
}

h5 {
  font-size: 1.8rem;
}

.wzmh-modal {
  .wzmh-modal-content {
    padding: 3.2rem;
  }
}

.wzmh-tag-checkable {
  padding: 0.7rem 1rem;

  &,
  &:active {
    background-color: $white;
    border-color: $grey-3;
    color: $grey;
  }

  &:not(.wzmh-tag-checkable-checked) {
    &:hover {
      background-color: $grey-4;
      border-color: $grey-3;
      color: $grey;
    }
  }

  &-checked {
    border: 0.5px solid $blue-light-40;

    &,
    &:hover,
    &:active {
      color: $blue;
      background-color: rgba($color: $blue, $alpha: 0.1);
    }
  }
}

hr {
  border-top: 0.5px solid $grey-3;
}

.wzmh-popover-content {
  .wzmh-popover-inner {
    padding: 2.4rem 2.8rem;
  }
}

.wzmh-tooltip {
  &-inner {
    font-size: 1.2rem;
    letter-spacing: 0;
  }
}

.display-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

a:-webkit-any-link {
  color: $black-light-10;
  cursor: pointer;
  text-decoration: none;
}

.text-color-primary {
  color: $primary-color;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-color-white {
  background-color: $white;
}

.position-fixed {
  position: fixed;
}

.align-items-base {
  align-items: baseline;
}

.align-items-center {
  align-items: center;
}

.scrollbar-visible {
  overflow-y: scroll;

  &:hover {
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $scroll-bar-bg;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }
  }
}

.wzmh-slider .wzmh-slider-mark-text-active {
  color: $primary-color;
  padding: 6px 0;
}

.wzmh-slider-horizontal .wzmh-slider-rail {
  background-color: $grey-3;
}

.wzmh-slider:hover .wzmh-slider-rail {
  background-color: $grey-3;
}

.wzmh-skeleton.wzmh-skeleton-element .breadcrumb-skeleton {
  width: 400px;
}

.wzmh-popover-content .wzmh-popover-inner {
  padding: 1.5rem;
}

.wzmh-input-status-error:not(.wzmh-input-disabled):not(
    .wzmh-input-borderless
  ).wzmh-input {
  border-color: $grey-4;
}

.wzmh-table-wrapper .wzmh-table-tbody > tr.wzmh-table-row-selected > td {
  background: none;
}

.aspect-ratio-1 {
  aspect-ratio: 1 !important;
}

.aspect-ratio-4_3 {
  aspect-ratio: 4/3 !important;
}

.multiline {
  white-space: pre-line;
}

.wzmh-popover {
  z-index: 10;
}
