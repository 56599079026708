@import "src/styles/variables";

.rounded-arrow {
    align-items: center;
    background-color: rgba($color: $grey-2, $alpha: 0.1);
    border-radius: 100%;
    display: flex;
    height: 2.4rem;
    justify-content: center;
    width: 2.4rem;
}