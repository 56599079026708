@import "src/styles/variables";

.input-number {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;

    .wzmh-input-number {
        border-inline-end: 0;
        padding: 0.6rem;

        &-group-addon {
            background-color: $white;
            border: 0;
        }
    }
}