@import "src/styles/variables";

.products-filter {
    &__location {
        display: flex;
        flex-direction: column;

        .location-input {
            grid-template-columns: 1fr;
            margin-bottom: 3.2rem;
        }
    }

    .wzmh-tabs-tab {
        width: 100%;

        &-btn {
            width: 100%;
            color: $grey;
        }

        &.wzmh-tabs-tab-active {
            .filter-label {
                .wzmh-badge-count {
                    background-color: rgba($color: $primary-color, $alpha: 0.1);
                    color: $primary-color;
                }
            }
        }
    }


}

.color-filter{
    &__options{
        .wzmh-checkbox-wrapper{
            --color-checkbox-bg: $primary-color;
            .wzmh-checkbox-inner{
                background-color: var(--color-checkbox-bg) !important;
                outline: 2px solid;
                outline-color: transparent;
                transition: none;
                border: none;

                &::after{
                    display: none !important;
                }
            }
        }

        .wzmh-checkbox-wrapper-checked {
            .wzmh-checkbox-inner{
                outline-offset: 1px;
                outline-color: var(--color-checkbox-bg);
            }
            .color-checkbox{
                &__label{
                    svg{
                        display: block;
                    }
                }
            }

            &:hover .wzmh-checkbox::after{
                display: none !important;
            }
        }

        .color-checkbox{
            &__label{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1rem;

                svg{
                    display: none;
                    padding: 0.3rem;
                    width: 0.8rem;
                    aspect-ratio: 1;
                    border-radius: 50%;
                    background-color: $primary-color;
                    fill: $white;
                }
            }
        }
    }
}