@import "src/styles/variables";

.container {
  &__row {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    width:100%;
    justify-content: space-between;
    &__switch {
      margin-left: 0;
    }

    &_icon {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
  &__active {
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 1.25rem;
    padding-inline: 2rem;
    padding-block: 0.8rem;
    margin-inline: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    background: rgba($background_green, 0.2) 0% 0% no-repeat padding-box;
  }

  &__inactive {
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    gap: 1.25rem;
    margin-inline: 1rem;
    margin-top: 0;
    padding-inline: 2rem;
    padding-block: 2rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    background: rgba($grey_3, 0.2) 0% 0% no-repeat padding-box;
  }

  &__text {
    text-align: left;
    letter-spacing: 0;
    color: $black-light-10;
    margin-left: 1.2rem;
  }

  &__subtext {
    text-align: left;
    letter-spacing: 0;
    color: $grey_2;
    font-size: 1rem;
    margin-left: 1.2rem;
  }
  &__subtext2 {
    text-align: left;
    letter-spacing: 0;
    color: $grey_2;
    font-size: 1rem;
  }

  svg {
    fill: $black-light-40;
  }
}
.headingSmall {
  text-align: left;
  letter-spacing: 0;
  color: $grey_2;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.switchSmall {
  text-align: left;
  letter-spacing: 0;
  color: $black-light-10;
  font-size: 1.3rem;
}
.column {
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  align-items: flex-start;
}
.icon {
  height: 1.5rem;
  width: 1.5rem;
  fill: $grey-3;
}

.icon_enabled {
  height: 1.5rem;
  width: 1.5rem;
  fill: $green;
}
.info-icon {
  height: 1rem;
  width: 1rem;

  fill: $grey-3;
  margin-left: 1rem;
}
.row {
  margin-top: 0.5rem;
}
