@import "src/styles/variables";

.plan-form {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin-inline: auto;
    max-width: 75.6rem;
    padding-block: 2.4rem;

    &__container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 2.4rem;

        .upload-input .uppy-DragDrop-container .uppy-DragDrop-inner::before {
            height: 2rem;
            width: 2rem;
            margin-top: 1.6rem;
        }

        .plan-form__description__diagram {
            .upload-input__wrapper {
                width: 200px;
                height: 150px;
            }

            .uploaded-image {
                width: 20rem;

                &__content {
                    width: 100%;
                    height: 15rem;
                    object-fit: cover;
                }
            }
        }

        &__diagram {

            .uppy-DragDrop-container {
                aspect-ratio: unset;
                padding-block: 2rem;
            }
        }

        .uploaded-image {
            &__content {
                width: 100%;
            }
        }

        .title-label {
            color: $black-light-40;
            font-size: 1.3rem;
            font-family: 'AvenirMedium';
            margin-bottom: 0.7rem;
        }

        &__img {
            .wzmh-space-vertical {
                width: 100%
            }

            .mg-r-8 {
                margin-right: 0.8rem;
            }

            .uploaded-image {
                width: 44px;

                &__content {
                    width: 44px;
                    height: 44px;
                }
            }

        }

        &__buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__form-action {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1.6rem;

                .button--delete {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 0.5rem;

                    .wzmh-btn-icon {
                        margin: 0 0 0.3rem 0 !important;
                    }

                    .icon-delete {
                        fill: $grey;
                        width: 1.6rem;
                        aspect-ratio: 1;
                    }
                }
            }
        }
    }

    &__description {
        &__container {
            width: 100%;
            max-width: 100%;
            padding: 1.2rem 1.6rem;
            border: 1px solid $border-color;
            border-radius: 8px;
            transition: all ease 0.2s;

            .wzmh-space,
            .wzmh-space-vertical {
                width: 100%;
            }

            &,
            * {
                box-sizing: border-box;
            }

            &:focus-visible,
            &:hover {
                border: 2px solid $primary-color;
            }
        }

        &__title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1.8rem;
            color: $grey-2;
            margin-bottom: 1rem;

            .icon-view-description {
                width: 1.8rem;
                aspect-ratio: 1;
                fill: $grey-2;
            }
        }

        &__input {
            padding: 0 !important;
            border: none !important;
            border-radius: 0 !important;
            box-shadow: none !important;
        }
    }

    &__diagram_loader {
        width: 200px;
        height: 150px;
    }


}

.wzmh-modal-body {
    justify-content: center;

}

.plan-form__container {
    .attachment-preview {
        width: 200px;

        &__placeholder {
            aspect-ratio: 2.5 !important;
            overflow: hidden;
        }

        .lazy-loader__container__loader__container {
            width: 100%;
            height: 100%;

            .wzmh-skeleton-image-svg {
                width: 3rem !important;
            }
        }
    }

    &__diagram {

        &,
        .uppy-Root {
            width: 200px;
            height: 150px;
        }
    }
}