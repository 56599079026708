@import "src/styles/variables";

.folder-header {
    display: flex;
    gap: 1.2rem;
    align-items: center;
    padding: 2rem 1.6rem;

    &__title {
        color: $grey-2;
        font-size: 1.3rem;
        letter-spacing: 0.13rem;
        text-transform: uppercase;
    }

    &__content {
        display: flex;
    }
}