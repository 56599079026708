@import "src/styles/variables";

.config-item-form {
    display: flex;
    align-items: center;

    &__add {
        border: 2px solid transparent;
        cursor: pointer;
        height: 1.6rem;
        width: 1.6rem;

        &:hover {
            border-color: rgba($color: $primary-color, $alpha: 0.5);
            border-radius: 100%;
        }
    }
}