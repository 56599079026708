@import "src/styles/variables";

.upload-input {
    display: block;
    position: relative;

    .label {
        display: inline-block;
        margin-bottom: 0.75rem;
    }

    .uppy-FileInput-container {
        .uppy-FileInput-btn {
            background-color: $primary-color;
            border-radius: 4px;
            border: 0;
            color: $white;
            cursor: pointer;
            font-family: "AvenirMedium";
            font-size: 1.5rem;
            line-height: 2.5;
            outline: 0;
            padding-inline: 1.6rem;

            &:hover,
            &:active {
                background-color: $primary-dark-10;
            }
        }
    }

    .uppy-DragDrop-container {
        aspect-ratio: 1 / 1;
        border-radius: 4px;
        border: 2px dashed $border-color;
        padding-inline: 2.4rem;

        .uppy-DragDrop-inner {
            padding: 0;

            &::before {
                content: url("../../../assets/icons/solid/image-upload.svg");
                display: inline-block;
                height: 3.2rem;
                margin-bottom: 1.2rem;
                width: 3.2rem;
            }

            .uppy-c-icon {
                display: none;
            }

            .uppy-DragDrop-label {
                color: $blue;
                font-size: 1.4rem;
                margin-bottom: 0.8rem;
                line-height: 2rem;
            }

            .uppy-DragDrop-note {
                color: $grey-2;
                font-size: 1.2rem;
                line-height: 1.6rem;
            }
        }

        &:focus {
            box-shadow: none;
        }
    }

    &--primary {
        .uppy-DragDrop-container {
            border: 0.5px dashed $primary-color;
            background-color: $attachment-primary-bg;

            .uppy-DragDrop-inner {

                &::before {
                    content: url("../../../assets/icons/multicolored/upload-with-ellipses.svg");
                }

                .uppy-DragDrop-label {
                    color: $grey;
                }
            }
        }
    }

    .uppy-StatusBar {

        .uppy-StatusBar-progress,
        &::before {
            display: none;
        }
    }
}

.upload-input__wrapper {
    position: relative;

    .attachment-upload-loader {
        position: absolute;
        background-color: $white;
        inset: 0;
        z-index: 2;
        border-radius: 4px;
        border: 2px dashed $border-color;

        div[role="button"] {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    svg {
        max-width: 5rem;
    }
}