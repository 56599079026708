@import "src/styles/variables";

.add-item {
    &__form {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        .upload-input .uppy-DragDrop-container .uppy-DragDrop-inner::before {
            height: 2rem;
            width: 2rem;
            margin-top: 1.6rem;
        }

        &__img {
            margin-right: 0.8rem;
        }

        &__label {
            font-size: 1.3rem;
            font-family: 'AvenirMedium';
            color: $black-light-40;
        }

        &__buttons {
            display: flex;
            gap: 2rem;
            margin-inline-start: auto;
            margin-bottom: -1.6rem;
        }
    }
}

.multiple-variant__image__container {
    .lazy-loader__container__image {
        width: 4.4rem;
        height: 4.4rem;
    }

    .lazy-loader__container__loader__container {
        width: 4.4rem;
        height: 4.4rem;
    }
}