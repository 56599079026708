
@import 'src/styles/variables';

.editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    border-radius: 80px;
    font-size: 12px;
  }
.iconSvg{
  fill: $primary-color;
  height: 1.2rem;

}
.iconSvgC{
  fill: $grey-3;
  height: 1.2rem;
  
}