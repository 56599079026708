@import "src/styles/variables";

.accordion-card {
    border: 0.5px solid $grey-4;
    border-radius: 16px;
    margin-bottom: 2.4rem;
    align-items: center;

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 1.6rem 1.67rem 1.6rem 2.4rem;
        align-items: center;
        cursor: pointer;

        &.border-bottom {
            border-bottom: 0.5px solid $grey-4;
        }

        &__name {
            font-family: 'AvenirHeavy';
            font-size: 1.6rem;
            color: $primary-black;
            align-items: 'center';

        }

        &__limit {
            color: $grey-2;
            font-size: 1.3rem;
            display: flex;
            align-items: 'center';
            font-family: 'AvenirMedium';

            &__count {
                color: $grey;
                align-items: 'center';
                font-family: 'AvenirHeavy';
            }

            .icon-accord {
                fill: $grey-4;
                height: 1.2rem;
                align-items: 'center';
                padding-top: 1rem;
                margin-left: 4.7rem;
            }
        }
    }
}

.spin {
    margin-left: 2rem;
    height: 10px;
    width: 70px;
    align-items: center;

    justify-content: center;
    padding: 0;
}