@import "src/styles/variables";

.attachment-preview {
  display: flex;
  flex-direction: column;
  min-height: 15rem;
  border: 0.5px solid $grey-4;
  border-radius: 8px;
  overflow: hidden;

  &__placeholder {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    .attachment-preview__placeholder__overlay {
      display: none;
    }

    &:hover {
      .attachment-preview__placeholder__overlay {
        align-items: center;
        background-color: rgba($color: black, $alpha: 0.85);
        display: flex;
        inset: 0;
        justify-content: center;
        position: absolute;

        .icon--delete {
          width: 2.16rem;
          height: 2.4rem;
        }

        .icon--download {
          width: 2.28rem;
          height: 2.28rem;
        }
      }
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;

    &__name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      text-align: center;
    }

    &__size {
      color: $grey-2;
    }
  }
}

.attachment-preview--compact {
  min-height: 12rem;

  &__placeholder {
    min-height: 8rem;
  }

  &__details {
    padding: 0.5rem;

    &__name {
      font-size: 0.9rem;
    }

    &__size {
      font-size: 0.8rem;
    }
  }
}
