@import "src/styles/variables";

.test-standards-detail {
  &__group {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 0.8rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 0.8rem;
    }
  }

  &__header {
    display: flex;
    align-items: center;

    .text-grey-2 {
      font-size: 1.4rem;
    }
  }

  &__description {
    display: flex;
    flex-direction: row;
    gap: 0.4rem;

    .text-grey-2 {
      font-size: 1.4rem;
    }
  }

  &__value {
    color: #262626;
    font-size: 1.5rem;
  }
}

.icon--test-standards {
  width: 1.6rem;
  height: 0.8rem;
}
