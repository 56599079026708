@import "src/styles/variables";

.user-form {
    margin: 1.7rem 22.8vw;

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 3.2rem;
        row-gap: 3.2rem;
        margin-top: 4.85rem;

        .input {
            width: 100%;
        }

        .wzmh-input-affix-wrapper {
            padding: 0 1.1rem;
        }

        .wzmh-space {
            width: 100% !important;
        }

        .dropdown-field .wzmh-select .wzmh-select-selector {
            height: 4.4rem;
            border-radius: 8px;
            font-size: 1.4rem;
        }

        .wzmh-switch:hover:not(.wzmh-switch-disabled) {
            background-color: $grey-4;
        }

        .wzmh-switch:not(.wzmh-switch-disabled) {
            background-color: $grey-4;
        }

        .wzmh-switch.wzmh-switch-checked:not(.wzmh-switch-disabled) {
            background-color: $green;
        }

        .switch__field {
            border: 1px solid $border-color;
            flex-direction: row-reverse;
            justify-content: flex-end;

            .switch__field__label--active,
            .switch__field__label--enabled {
                color: $green;
            }

            .switch__field__label--disabled {
                color: $grey-2;
            }
        }

        .status-switch {
            .wzmh-switch:hover:not(.wzmh-switch-disabled) {
                background-color: $danger-color;
            }

            .wzmh-switch:not(.wzmh-switch-disabled) {
                background-color: $danger-color;
            }

            .wzmh-switch.wzmh-switch-checked:not(.wzmh-switch-disabled) {
                background-color: $green;
            }

            .switch__field__label--inactive {
                color: $danger-color;
            }
        }

    }

}

@media (max-width:769px) {
    .user-form {
        margin: 1.7rem 3.4rem;
    }
}

.password-label-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .password-label {
        margin-top: 0;
        margin-bottom: 0.75rem;
    }
}