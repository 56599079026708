@import "src/styles/variables";

.prototype-form {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin-inline: auto;
    max-width: 75.6rem;
    padding-block: 2.4rem;

    &__container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: 2.4rem;

        .upload-input .uppy-DragDrop-container .uppy-DragDrop-inner::before {
            height: 2rem;
            width: 2rem;
            margin-top: 1.6rem;
        }

        .uploaded-image__content{
            width: 4.4rem;
            height: 4.4rem;
        }

        .lazy-loader__container__loader__container{
            width: 4.4rem;
        }

        .title-label {
            color: $black-light-40;
            font-size: 1.3rem;
            font-family: 'AvenirMedium';
            margin-bottom: 0.7rem;
        }

        &__img {
            .wzmh-space-vertical {
                width: 100%
            }

            .mg-r-8 {
                margin-right: 0.8rem;
            }
        }

        &__buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__form-action {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 1.6rem;

                .button--delete {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 0.5rem;

                    .wzmh-btn-icon {
                        margin: 0 0 0.3rem 0 !important;
                    }

                    .icon-delete {
                        fill: $grey;
                        width: 1.6rem;
                        aspect-ratio: 1;
                    }
                }
            }
        }
    }

    &__description {
        &__container {
            width: 100%;
            max-width: 100%;
            padding: 1.2rem 1.6rem;
            border: 1px solid $border-color;
            border-radius: 8px;

            .wzmh-space,
            .wzmh-space-vertical {
                width: 100%;
            }

            &,
            * {
                box-sizing: border-box;
            }

            &:focus-visible,
            &:hover {
                border: 2px solid $primary-color;
            }
        }

        &__title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1.8rem;
            color: $grey-2;
            margin-bottom: 1rem;

            .icon-view-description {
                width: 1.8rem;
                aspect-ratio: 1;
                fill: $grey-2;
            }
        }

        &__input {
            padding: 0 !important;
            border: none !important;
            border-radius: 0 !important;
            box-shadow: none !important;
        }
    }
}

.wzmh-modal-body {
    justify-content: center;
}