.lazy-loader__container {
  &__loader__container {
    width: 100%;
    overflow: hidden;

    .wzmh-skeleton,
    .wzmh-skeleton-image {
      width: 100% !important;
      height: 100% !important;
      overflow: hidden;
    }

    svg {
      width: 40% !important;
      height: auto;
      aspect-ratio: auto;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    max-width: 100%;
    object-fit: contain;
  }
}

.aspect-square,
.aspect-1 {
  aspect-ratio: 1 !important;
}

.aspect-auto {
  aspect-ratio: auto;
  height: auto;
  max-height: none !important;
}
