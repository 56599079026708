@import "src/styles/variables";

.dual-view {
  background: $black-transparent-10;
  height: $prototype-view-height;
  display: flex;

  &__left-container {
    padding: 2.4rem 0 3.2rem 2.4rem;
    margin-right: 1.8rem;
    max-width: 350px;

    &__back-btn {
      color: $grey;
      letter-spacing: 0;
      cursor: pointer;

      .icon--back {
        gap: 0.8rem;
        padding-right: 0.5rem;
      }

      span {
        color: $grey-6;
        font-family: "DM Sans";
        font-size: 1.5rem;
      }
    }

    .sub-view-list {
      margin-top: 1.6rem;
      max-height: calc(100vh + 3.2rem - $navbar-height - 21.083rem);
      max-width: 350px;
      width: 350px;
      overflow-y: scroll;
      padding: 0;

      .card-container {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__add-sub-view {
      padding: 1.6rem;
      padding-top: 0;
      color: $danger;
      cursor: pointer;

      &__wrapper {
        display: flex;
        align-items: center;

        .pd-8 {
          padding: 0 0.8rem;
        }

        .add-icon {
          height: 1.6rem;
          border-radius: 50%;
          fill: $add-icon-white;
          background-color: $primary-color;
          border: none;
          padding: 0;
        }
      }
    }
  }

  &__right-container {
    display: block;
    position: relative;
    flex: 5;
    background-color: $white;
    height: 120vh;
    overflow-y: scroll;
    margin: 2.4rem 2.4rem 3.2rem 0;
    // &::-webkit-scrollbar {
    //   -webkit-appearance: none;
    //   width: 0;
    // }
    &.diagram-view-expand {
      margin: 0;
      height: 120vh;
    }

    &__details {
      padding: 2.4rem 2.4rem 3.2rem;

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        max-width: 100%;

        .dual-view-icons__icon-gp {
          display: flex;

          &__icon {
            border-radius: 8px;
            border: 1px solid $grey-4;
            height: 3.2rem;
            width: 3.2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 1.4rem;
            cursor: pointer;

            .icon {
              fill: $grey-2;
              height: 1.2rem;
              width: 1.2rem;
            }

            &:hover .icon {
              fill: $primary-color;
            }
          }

          .icon--edit {
            width: 2.5rem;
            height: 2.5rem;
            border: 2px solid $grey-10;
            padding: 6px;

            .cls-1 {
              fill: $grey-6 !important;
              stroke-width: 0px;
            }

            .cls-2 {
              fill: none !important;
              stroke: white !important;
              stroke-miterlimit: 10;
              stroke-width: 0.6px;
            }

            &:hover {
              cursor: pointer;
              .cls-1 {
                fill: $primary-color !important;
                stroke-width: 0px;
              }
            }
          }
        }
      }

      &__description {
        margin: 1.6rem 0 2.4rem 0;
        overflow-y: scroll;
        max-height: calc($prototype-view-height - 24rem);

        .description--text {
          max-width: 80ch;
        }

        &__text {
          margin: 0 12rem 2.4rem 0;
        }

        &--action {
          display: inline-block;
          padding-left: 0.5rem;
          cursor: pointer;
          text-decoration: underline;
        }

        .display--none {
          display: none;
        }
      }
    }
  }

  .top-level-heading {
    color: $grey-6;
    font-family: "DM Sans";
    font-size: 2.5rem;
    font-weight: bold;
    padding-top: 1.4rem;
  }
}

.dual-view__right-container__image-skeleton {
  height: $prototype-view-height !important;
  width: 100% !important;

  .wzmh-skeleton-image-svg {
    width: 4.8rem !important;
    aspect-ratio: 1 !important;
  }
}
