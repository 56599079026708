@import 'src/styles/variables';

.space-gallery {
    margin-top: 2.4rem;
    display: flex;
    // grid-template-columns: repeat(3, 1fr);
    column-gap: 1.6rem;
    row-gap: 1.6rem;
    flex-wrap: wrap;
    max-width: 100ch;

    &__item {
        height: 15rem;
        width: 20rem;
        max-height: 15rem;
        max-width: 20rem;
        border-radius: 0.4rem;
        aspect-ratio: 4/3;
    }

    .uploaded-image {
        width: 100%;
        aspect-ratio: 4/3;
        display: flex;
        justify-content: center;
        align-items: center;

        &__container {
            width: 100%;
            aspect-ratio: 4/3;
        }

        &__content {
            width: 100%;
            height: auto;
            aspect-ratio: auto;
        }
    }

    .image-preview__content {
        img {
            width: auto !important;
            height: auto !important;
            max-height: 80vh;
            aspect-ratio: auto !important;
        }
    }

    .gallery--image {
        width: 100%;
    }
}

.space-gallery .upload-input .uppy-DragDrop-container {
    aspect-ratio: 4/3;
}

@media (max-width:1086) {
    .space-gallery {
        padding-right: 0;
    }
}

@media (max-width:1000) {
    .space-gallery {
        padding-right: 0;
        grid-template-columns: repeat(2, 1fr);
    }
}

.space-gallery__item {
    .uploaded-image {
        width: 100%;
        aspect-ratio: 4/3;

        .lazy-loader__container__loader__container {
            aspect-ratio: 4/3 !important;
        }

        .uploaded-image__content{
            aspect-ratio: auto !important;
        }
    }
}