@import "src/styles/variables";

.types-navbar {
    margin: 2.5rem 0 1.2rem;
    padding-bottom: 2.8rem;
    background: $white 0 0 no-repeat padding-box;
    border-bottom: 0.5px solid $grey-4;

    img {
        cursor: pointer;
    }

    &__text {
        text-align: center;
        font-size: 1.3rem;
        letter-spacing: 0;
        color: $black-light-40;
        opacity: 1;
        cursor: pointer;
        margin-top: 1.2rem;
    }

    &__image{
        width: 50px;

        svg{
            width: 2.4rem !important;
            height: auto;
        }
    }

    .pd-r-32 {
        padding-right: 3.2rem;
    }
}

.filtered-products {
    margin-top: 1.6rem;
    padding: 0 10rem;

    &__breadcrumb {
        text-align: left;
        font-size: 1.2rem;
        letter-spacing: 0;
        color: $black-light-10;
    }

    &__header {
        margin: 1.6rem 0 2.5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &__left {
            display: flex;
            align-items: baseline;

            &__heading {
                color: $black-light-10;
                text-align: left;
                font-size: 2.4rem;
                letter-spacing: 0;
            }

            &__count {
                text-align: left;
                font-size: 1.2rem;
                letter-spacing: 0;
                color: $black-light-40;
                margin-left: 1.2rem;
            }
        }

        .sort-container {
            background: $white 0 0 no-repeat padding-box;
            border: 1px solid $primary-light-90;
            border-radius: 8px;
            padding-left: 1.6rem;

            &__heading {
                text-align: left;
                font-size: 1.5rem;
                font-family: 'AvenirHeavy';
                letter-spacing: 0;
                color: $black-light-40;
            }

            .min-width-7 {
                min-width: 17rem;
            }
        }
    }

    .products-grid {
        margin-top: 3.2rem;

        &__carousel {
            margin-bottom: 1.6rem;
            border-radius: 8px;
            position: relative;

            img {
                width: 100%;
                object-fit: cover;
                border-radius: 8px;
            }

            .product-count {
                background-color: $black-light-10;
                padding: 0.3rem 0.5rem;
                border-radius: 12px;
                position: absolute;
                top: 1rem;
                right: 1rem;
                color: $white;
            }

            &:hover {
                border: 0.5px solid $grey-4;
            }

            &__text {
                background-color: $black-light-10;
                text-align: center;
                opacity: 0.97;
                position: absolute;
                color: $white;
                width: 100%;
                bottom: 0;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                font-family: 'AvenirBook';
                font-size: 1.4rem;
                max-height: 0;
                overflow: hidden;
                transition: max-height 350ms ease-in-out;

                .pd-t-b {
                    padding: 0.4rem 0;
                    position: relative;
                }

                .left-abs {
                    position: absolute;
                    left: 1.2rem;
                }

                .right-abs {
                    position: absolute;
                    right: 1.2rem;
                }
            }
        }

        &__heading {
            margin-bottom: 1.6rem;
            text-align: left;
            font-size: 1.5rem;
            letter-spacing: 0;
            color: $black-light-10;
        }

        &__price {
            margin-bottom: 5.2rem;
            text-align: left;
            font-size: 1.5rem;
            font-family: 'AvenirHeavy';
            letter-spacing: 0;
            color: $black-light-10;
            opacity: 1;
        }

        &__vendor {
            font-family: 'AvenirHeavy';
            font-size: 1.4rem;
            color: $black-light-10;
            margin-bottom: 1.6rem;
        }
    }
}

.margin-top-10 {
    margin-top: 7.4rem;
}

.products-grid__carousel:hover .products-grid__carousel__text {
    max-height: 50%
}

.products-grid__carousel:hover .product-count {
    display: block;
}

@media (max-width:1024px) {
    .filtered-products {
        padding: 0 3.4rem;
    }

    .types-navbar {
        padding: 0 3.4rem;
    }
}

.products-grid__carousel {

    .product-count,
    .product-status {
        padding: 0.5rem 0.8rem;
        border-radius: 60px;
        position: absolute;
        text-align: center;

        p {
            font-size: 1.4rem;
            line-height: 1.4rem;
            margin: 0;
            padding: 0;
        }
    }

    .product-count {
        background-color: $black-light-10;
        top: 1rem;
        right: 1rem;
        color: $white;
        overflow: hidden;

        transition: all ease-in-out 0.35s;
        transform-origin: right;
    }

    &:hover {
        .product-count {
            &.all-variant {
                opacity: 0;
                transform: scaleX(0.5);
            }
        }
    }

    .product-status {
        background-color: transparentize($color: $primary-color, $amount: 0.85);
        color: $primary-color;
        bottom: 1rem;
        right: 1rem;

        p {
            text-transform: capitalize;
            font-weight: bold;
        }

        span.middot {
            display: inline-block;
            width: 0.6rem;
            aspect-ratio: 1;
            border-radius: 50%;
            background-color: $primary-color;
            vertical-align: 10%;
        }
    }
}

.product__carousel {
    .add__to__picklist {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $primary-color;
        padding-block: 0.5rem;
        gap: 0.5rem;

        p {
            margin: 0;
            padding: 0;
        }

        &__icon {
            fill: white;
            width: 1.8rem;
            height: 1.8rem;
        }
    }

    & &__action {
        background-color: white;
        color: $grey;
    }
}

.products-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;

    .page-action {
        display: flex;
        gap: 1rem;

        button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 0.5rem;
        }

        .back-button {
            fill: $grey-2;

            &:disabled {
                fill: $white;
            }
        }

        .next-button {
            fill: $white;

            &:disabled {
                color: $white;
            }
        }

        svg {
            width: 1em;
        }

        .invert--hr {
            transform: rotate(180deg);
        }

    }

    .page-counter {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        form {
            max-width: 16rem;
        }

        .wzmh-space-item {
            font-size: 14px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.5rem;

            .input-number {
                display: flex;
                flex-direction: row;
                gap: 0.8rem;
                align-items: center;

                .wzmh-input-number {
                    border: 1px solid $grey-4;
                    border-radius: 10px;

                    &:hover,
                    &.wzmh-input-number-focused {
                        border-color: $primary-color;
                    }
                }
            }
        }
    }
}