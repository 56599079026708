@import "src/styles/variables";

.products {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    padding: 2.4rem 4rem;

    &__header {
        display: flex;
        align-items: center;
        gap: 1.6rem;
        z-index: 2;

        &__title {
            font-size: 2.4rem;
        }

        &__icon {
            line-height: 1;

            svg {
                fill: $primary-color;
                width: 2.4rem;
                height: 2.4rem;
            }
        }
    }

    &__main {
        &__name {
            display: flex;
            gap: 1rem;
            align-items: center;
        }
    }

    .product-status {
        & {
            display: flex;
            gap: 0.6rem;
            align-items: center;

            &::before {
                border-radius: 100%;
                content: "";
                display: inline-block;
                height: 0.6rem;
                width: 0.6rem;
            }

            &.active::before {
                background-color: $green;
            }

            &.in_active::before {
                background-color: $danger-color;

            }


        }
    }
}

.product-filter-title {
    font-family: "AvenirHeavy";
    font-size: 1.8rem;
    text-transform: capitalize;
    margin-bottom: 2.4rem;
}

.filter-container__text {
    font-family: "DM Sans";
}