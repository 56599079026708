@import "src/styles/variables";

.sustainable-card {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
  padding-top: 2rem;
  background-color: $sustainable;

  &__title {
    align-items: center;
    color: $sustainable-product;
    display: flex;
    font-family: "AvenirHeavy";
    font-size: 1.8rem;
    gap: 1rem;
  }

  &__description {
    margin-bottom: 0.8rem;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2.8rem;
    letter-spacing: 0%;
    color: #5f5f5f;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &-item {
      display: flex;

      gap: 0.8rem;

      .label {
        font-weight: 400;
        color: $black;
        font-size: 1.5rem;
        min-width: 140px;
      }

      // .value {
      //   flex: 1;
      // }
    }
  }

  &__title,
  &__description,
  &__details {
    padding-inline: 2rem 2.2rem;
  }

  &__image {
    width: 100%;
  }
}
