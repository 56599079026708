@import "src/styles/variables";

.list-variant {
    display: grid;
    grid-template-columns: repeat(auto-fill, 9rem);
    grid-template-rows: repeat(auto-fill, 9rem);
    row-gap: 2.4rem;
    column-gap: 1rem;

    &__item {
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
        }

        &--active {
            border-radius: 8px;
            border: 2px solid $primary-color;
        }
    }
}