@import "src/styles/variables";

.pagination {
    &__details {
        font-size: 1.3rem;
    }

    .wzmh-pagination-item-active {
        background-color: $grey-3;
        border: 0;
    }


    .wzmh-pagination-prev,
    .wzmh-pagination-next {
        svg {
            width: 0.7rem;
            height: 1.1rem;
        }

        &.wzmh-pagination-disabled {
            svg {
                opacity: 0.4;
            }
        }
    }
}