@import "src/styles/variables";

.add-variant {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  &__color-row,
  &__material-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.4rem;
    align-items: flex-start;
  }

  &__color {
    .color-label {
      margin-top: 0;
      margin-bottom: 0.75rem;

      &-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
    }
  }
}
