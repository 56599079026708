.warranties {
  &__header {
    font-weight: 500;
    font-size: 1.8rem;
    font-family: "AvenirHeavy";
    margin-bottom: 1rem;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__field-row {
    display: flex;
    gap: 1rem;
    align-items: flex-end;
  }

  &__type-period {
    flex: 1;
    display: flex;
    gap: 1rem;

    > * {
      flex: 1;
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__add-btn {
    margin-top: 0.5rem;
  }

  &__remove-btn {
    color: #ff4d4f;
    margin-bottom: 0.5rem;

    &:hover {
      color: #ff7875;
    }
  }
}

.warranties__dropdown {
  .wzmh-select-selector {
    height: 4.4rem !important;
  }
}
