@import "src/styles/variables";

.wzmh-modal {
    .wzmh-modal-body {
        display: flex;
        gap: 1.6rem;
    }
}

.delete-modal {
    display: flex;
    gap: 1.6rem;

    &__end {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;

        &__title {
            font-size: 2.4rem;
        }

        &__sub-title {
            color: $grey-2;
            font-size: 1.3rem;
        }

        &__buttons {
            margin-top: 1.8rem;
            display: flex;
            gap: 1.6rem;
        }
    }
}