@import "variables";

/* Helpers CSS */

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.float-right {
  float: right !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-bold {
  font-weight: bold;
}

.text-underline {
  text-decoration: underline !important;
}

.text-primary {
  color: $primary-color;
}

.text-book {
  font-family: "AvenirBook";
}

.text-medium {
  font-family: "DM Sans";
  font-weight: 500;
}

.text-light {
  font-family: "AvenirLight";
}

.text-heavy {
  font-family: "AvenirHeavy";
}

.text-regular {
  font-family: "DM Sans";
}

.text-danger {
  color: $danger-color;
}

.text-white {
  color: #FFFFFF !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-sm {
  font-size: 1.2rem;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.float-none {
  float: none !important;
}

.primary-black {
  color: $primary-black !important;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}


/* Padding */

.p-0 {
  padding: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

/* Margins */

.mt-0 {
  margin-top: 0 !important;
}

// Icon
.icon {
  fill: $grey-2;
}

.icon-grey-3 {
  fill: $grey-3
}

.collapse-icon-header {
  align-items: center;
  display: flex;
  font-size: 1.8rem;
  gap: 1.2rem;
}

.icon--sustainable {
  .cls-1 {
    fill: $sustainable-cls-1
  }

  .cls-2 {
    fill: $sustainable-product;
  }
}

.icon.icon--sustainable {
  .cls-1 {
    fill: $white;
  }

  .cls-2 {
    fill: $grey-3;
  }
}

.icon-blue-20 {
  fill: $blue-light-20;
}

.icon-primary {
  fill: $primary-color;
}

.icon-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  gap: 1.2rem;
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.text-upper {
  text-transform: uppercase;
}

.bl-1 {
  border-inline-start: 1px solid $grey-3;
}

.text-placeholder {
  opacity: 0.4;
}

.text-grey-2 {
  color: $grey-2;
}

.rounded-brackets {
  &::before {
    content: "(";
  }

  &::after {
    content: ")";
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

// Grid
.grid--1X3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem
}
.display-grid{
  display: grid
}
.grid-rows-2{
  grid-template-rows: repeat(2, 1fr);
}

.quotes {
  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
}
.space-between{
  justify-content: space-between;
}
.hr-line{
  border-bottom: 1px solid $grey-4;
  margin: 2rem 0 2.5rem;
}
.mg-b-2{
  margin-bottom: 2.5rem;
}
.ht-100{
  height: 100%;
}
.pin-marker-details{
  display: flex;
  justify-content: space-between;
  .title{
    font-size: 1.4rem;
    margin-right: 3rem;
    font-family: 'AvenirMedium';
  }
  .icon-pin {
      height: 1.6rem;
      width: 1.6rem;
      fill: $black-light-40;
      margin-left: 1.6rem;
      cursor:pointer
    }
  
    .icon-pin:hover {
      fill: $grey;
    }
}
.display-none{
  display: none;
}