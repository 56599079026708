@import "src/styles/variables";

.prototypes {
    padding: 2.4rem 4rem;
    padding-bottom: 0;
    overflow: hidden;

    &__container {
        background-color: $white;
        display: flex;
        width: 100%;
        height: calc(100vh - ($navbar-height + 2.4rem));
        border-radius: 8px;

        &__left {
            min-width: 31.6rem;
            overflow-y: scroll;
        }

        &__separator {
            background-color: $grey-4;
            display: inline-block;
            min-width: 0.4rem;
        }

        &__right {
            flex-grow: 1;
            overflow-y: scroll;
        }
    }
}