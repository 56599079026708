@import "src/styles/variables";

.client-card {
  display: flex;
  align-items: center;
  gap: 1.25rem; 
  opacity: 1;
  justify-content: center;
  border-radius: 2rem; 
  padding: 3rem; 
  margin: 1rem; 
  background: $white 0% 0% no-repeat padding-box;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1); 
}

.client-info {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  gap: 0.3125rem; 
  align-items: flex-start;
}

.title {
  font-size: 2.6rem; 
  font-family: 'AvenirBlack';
  letter-spacing: 0;
  color: $black-light-10;
}

.row {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  &_icon-phone {
    height: 1.5rem;
    width: 1.5rem;
    fill:$grey-3;
    padding-left: 1rem;
    
  }
  &_icon-mail {
    height: 1.5rem;
    width: 1.5rem;
    fill:$grey-3;
    
  }
  
}

.subtitle {
  font-size: 1.5rem;
}

.edit-icon {
  fill: $grey-2;
  width: 2rem; 
  height: 2rem; 
  bottom: 3rem; 
  right: 2rem;
  position: relative;
  margin-left: auto;
  cursor: pointer;
}

.card,
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6.25rem; 
  height: 6.25rem;
  border-radius: 3.125rem;
  background-color: $circle-avatar-color;
  color: white;
  font-size: 1.5rem;
}

.circle {
  width: 5rem;
  height: 5rem; 
}
.uploaded-image-circle{
  width: 10rem;
  height: 10rem;
}