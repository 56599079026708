@import "src/styles/variables";

.filter-label {
    display: flex;
    justify-content: space-between;

    &__count {

        .wzmh-badge-count {
            background-color: rgba($color: $primary-color, $alpha: 0.1);
            color: $primary-color;
        }

        .wzmh-badge-count {
            background-color: rgba($color: $grey-2, $alpha: 0.1);
            box-shadow: none;
            color: $grey-2;
            font-size: 1rem;
            padding: 0.1rem 0.5rem;
        }
    }
}