@import "src/styles/variables";

.empty-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    &__select {
        .icon--select {
            fill: $grey-3;
        }
    }

    &__title {
        color: rgba($color: $grey-2, $alpha: 0.4);
        font-size: 1.2rem;
    }
}