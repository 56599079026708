@import "src/styles/variables";

.switch {
    display: flex;
    gap: 0.8rem;
    flex-direction: column;

    &__field {
        border-radius: 6px;
        display: flex;
        gap: 1rem;
        padding: 1rem 1.2rem;

        &__label {
            font-family: "AvenirHeavy";
            text-transform: capitalize;
        }
    }
}