@import "src/styles/variables";

.category-configuration {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    background: $white;
    border-radius: 8px;
    height: 100%;

    &__item {

        &:nth-child(2) {
            border-inline: 4px solid $grey-4;
        }
    }


}