@import "src/styles/variables";

.delete-product-mapping {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__header {
        border-block-end: 0.5px solid $grey-3;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        padding-block-end: 3.2rem;
        margin-block-end: 2.4rem;

        &__title {
            font-size: 2.4rem;
        }

        &__sub-title {
            color: $grey-2;
        }
    }

    &__main {
        &__footer {
            margin-block-start: 3.4rem;
            display: flex;
            gap: 1.6rem;
        }
    }
}

.config-delete-modal {
    .wzmh-modal-content {
        &:has(.loader) {
            .loader {
                margin: -4rem -2.4rem;
                padding: 4rem 2.4rem;
                width: 100%;
                border-radius: 8px;
            }
        }

        .loader {
            min-height: 100%;
        }
    }
}