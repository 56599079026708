@import "src/styles/variables";

.inventory-details {
  .wzmh-collapse-content-box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 2.4rem;

    .text-grey-2 {
      font-size: 1.4rem;
      width: 50%;
    }

    .inventory-details__value {
      font-size: 1.5rem;
      width: 50%;
    }
  }
}
