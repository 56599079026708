@import "src/styles/variables";

.product-form-content-left {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    flex-grow: 1;

    &__name {
        font-size: 1.8rem;
        font-family: "AvenirHeavy";

        input {
            padding-block: 0.95rem;
        }
    }
}