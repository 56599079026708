@import 'src/styles/variables';
.grouped-pin {
  background-color: $pin-marker-color;
    cursor: pointer;
    display: inline-block;
    font-size: 12px;
    height: 24px;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 24px;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $white;
    border-radius: 50%;
}
