@import "src/styles/variables";

.home {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__content {
        margin-top: $navbar-height;
        flex-grow: 1;

        &:has(.products),
        &:has(.configurations),
        &:has(.prototypes),
        &:has(.plan-view) {
            background-color: $secondary-bg;
        }
    }
}