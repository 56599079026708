@import "src/styles/variables";

.search-filter-container{
    max-width: 100%;
    padding: 1.6rem 0;
    &.pd-lr{
        padding:1.6rem 10rem ;
    }
    background-color: $white;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    &__content{
        display: flex;
        .sort-container {
                background: $white 0 0 no-repeat padding-box;
                border: 1px solid $primary-light-90;
                padding: 0 1rem 0 1.6rem ;
                &__heading {
                    text-align: left;
                    font-size: 1.5rem;
                    font-family: 'AvenirMedium';
                    letter-spacing: 0;
                    color: $grey-2;
                }
                margin-left: 1.6rem;
                height: 4rem;
            }
        .search-bar{
            display: flex;
            justify-content: space-between;
            width: 50.2rem;
            padding:0.3rem;
            height: 3.4rem;
            border: 1px solid $primary-color;
            background: $white 0 0 no-repeat padding-box;
            opacity: 1;
            ::placeholder{
                text-align: left;
                letter-spacing: 0;
                color: $black-light-40;
                opacity: 1;
            }
            .border-left{
                width: 1px;
                background-color: $primary-light-90;
            }
            &__icon{
                background-color: $primary-color;
                padding: 0.85rem;
                width: 1.7rem;
                height: 1.7rem;
                margin-left: 1.3rem;
                svg{
                    fill: $white;
                    height: 1.6rem;
                    width: 1.6rem;
                }
            }
            .input{
                width: 100%;

                .search-input{
                    width: 100%;
                }

                input{
                    width: 100%;
                    
                    &::placeholder {
                        color: $grey-10;
                        font-family: "DM Sans";
                        font-size: medium;
                        font-weight: 300;
                    }
                }
            }

            .wzmh-select-selection-item {
                color: $grey-10;
            }
        }
    }
 
}
.search-filter-container{
    max-width: 100%;
}
@media (max-width:1024px) {
    .search-filter-container.pd-lr {
        padding: 0 3.4rem;
        width: calc(100vw - 6.8rem);
    }
}
@media (max-width:830px) {
    .search-filter-container {
        display: block;
    }
}
