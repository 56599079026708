@import 'src/styles/variables';

.notification-popup {
    &__root {
        width: 41rem;
        position: fixed;

        .wzmh-popover-content{
            margin-right: 1.6rem !important;
        }

        &,
        .wzmh-popover-content,
        .wzmh-popover-inner,
        .wzmh-popover-inner-content {
            margin: 0;
            padding: 0;
            border-radius: 24px;
        }

        .loader-spinner {
            height: 13.6rem;

            .wzmh-spin-dot-item {
                background-color: $primary-color;
            }
        }
    }

    max-height: $notification-popup-height;
    background-color: $white;
    min-width: 34rem;
    padding: 1rem;
    border-radius: 24px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $grey;

        &__title {
            font-size: 2rem;
            font-family: 'AvenirHeavy';
        }

        &__clearall {
            font-size: 1.5rem;
            font-family: 'RalewaySemiBold';
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                border-bottom: 0.5px solid $grey-2;
            }
        }

        padding-bottom: 2.6rem;
        border-bottom: 1px solid $grey-5;
    }

    &__body {
        padding-top: 2.6rem;
        max-height: calc($notification-popup-height - 8.5rem);
        overflow: scroll;
        max-width: 100%;

        .notification-card {
            display: flex;
            align-items: center;
            margin-bottom: 4rem;
            max-width: 100%;

            &__icon {
                border-radius: 50%;
                margin-right: 1.6rem;
                background-color: $icon-background-green;
                height: 5rem;
                width: 5rem;
                display: flex;
                justify-content: center;
                align-items: center;

                &.grey {
                    background-color: $icon-background-grey;
                }

                .icon-activity {
                    height: 1.5rem;
                    width: 1.5rem;
                    fill-rule: evenodd;
                    opacity: 1;

                    .cls-update-activity-3 {
                        fill: $primary-color
                    }
                }
            }

            &__details {
                &__date {
                    font-size: 1.2rem;
                    color: $grey-2;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .wzmh-empty-normal {
            max-width: 100%;
        }

    }

    &.hidden {
        display: none
    }
}