@import "src/styles/variables";
.list-container{
    margin: 1.6rem 3.2rem 1.9rem;
    &__back-icon{
        margin-bottom: 0.8rem;
    }
    &__table{
        margin-bottom: 1.6rem;
        .expand-row{
            .icon--down-arrow{
                height: 0.6rem;
                width: 1rem;
                fill: $grey-2;
            }
        }
    }
}