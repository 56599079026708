@import "src/styles/variables";

.uploaded-image {
    position: relative;
    line-height: 0;
    border-radius: 4px;
    overflow: hidden;

    &__content {
        aspect-ratio: 1;
    }

    &__container {
        position: relative;
        overflow: visible;

        .uploaded-image__delete--overlay {
            display: none;
        }

        &:hover {
            .uploaded-image__delete--overlay {
                fill: $grey;
                display: block;
                position: absolute;
                top: -0.7rem;
                right: -0.7rem;
                width: 1.4rem;
                background-color: $white;
                border-radius: 50%;
                border: 2px solid $white;
                cursor: pointer;
            }
        }
    }

    &__overlay {
        display: none;
        position: absolute;
        inset: 0;
        font-size: 1.5rem;
        cursor: pointer;

        &__content {
            line-height: 2.5rem;
            display: flex;
            flex-direction: column;
            gap: 0.8rem;
            align-items: center;
        }
    }

    &:hover {
        .uploaded-image__overlay {
            background-color: rgba($color: $black, $alpha: 0.8);
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.uploaded-image-circle {
    position: relative;
    line-height: 0;
    border-radius: 50%;
    overflow: hidden;

}