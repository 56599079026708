@import "src/styles/variables";

.dropdown-field {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .wzmh-select {
        &:hover {
            .wzmh-select-selector {
                border-width: 2px;
            }
        }

        .icon--opened {
            transform: rotate(180deg);
        }

        .wzmh-select-selector {
            align-items: center;
            font-size: 1.5rem;
            height: 3.6rem;
            padding: 1.2rem 1rem;
            font-family: "AvenirMedium";

            .wzmh-select-selection-search {
                display: flex;
                align-items: center;
                inset-inline: 1.6rem;

                input[type=search] {}
            }
        }

        .wzmh-select-selection-placeholder {
            align-items: center;
            display: flex;
            opacity: 0.4;
            height: auto;
            padding-left: 0.6rem;
        }
        .wzmh-select-selection-item{
            padding-left: 0.6rem;
        }

        &-arrow {
            svg {
                width: 1rem;
                height: 0.6rem;
            }
        }
    }


}