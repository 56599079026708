@import "src/styles/variables";

.warranty-details {
  &__group {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      border-bottom: 1px solid #f0f0f0;
      margin-bottom: 0.8rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
  }

  &__row {
    display: flex;
    flex-direction: row;
    gap: 0.4rem;
  }

  &__label {
    color: $black;
    font-size: 1.4rem;
    width: 50%;
  }

  &__value {
    font-size: 1.5rem;
    width: 50%;
  }

  .wzmh-collapse-content-box {
    gap: 0.5rem;
  }

  .text-grey-2 {
    font-size: 1.4rem;
  }
}

.icon--warranty {
  width: 1.6rem;
  height: 0.8rem;
}
