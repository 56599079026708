@import "src/styles/variables";

.picklist-drawer {
    $padding: 2rem 2.5rem;

    .wzmh-drawer-body {
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1.6rem;
        box-shadow: 0px 10px 30px rgba($color: $grey, $alpha: 0.07);
        padding: $padding;


        &__title {
            font-size: 2.4rem;
            margin-right: auto;
        }
    }

    &__main {
        padding: $padding;
    }

}

.picklist-drawer-root {
    .wzmh-drawer-content-wrapper {
        min-width: 56.5rem;
    }
}

.picklist-drawer__main {
    width: 100%;
    box-sizing: border-box;
    position: relative;

    &__product {
        &__container {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            background: $white;
            border-radius: 16px;
            padding: 2rem;
            gap: 2rem;
            box-shadow: 0 10px 40px $black-transparent;
        }

        &__image {
            min-width: 16rem;
            max-width: 17rem;
            aspect-ratio: 1;
        }

        &__details {
            p {
                margin-block: 0.5rem;
            }
        }

        &__vendor {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 13px;
        }

        &__name {
            font-size: 16px;
        }

        &__variant {
            font-size: 15px;
            color: $primary-dark-40;
        }

        &__qty {
            font-size: 14px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.5rem;

            .input-number {
                display: flex;
                flex-direction: row;
                gap: 0.8rem;
                align-items: center;

                .wzmh-input-number {
                    border: 1px solid $grey-4;

                    &:hover,
                    &.wzmh-input-number-focused {
                        border-color: $primary-color;
                    }
                }
            }
        }

        &__cost {
            font-weight: bold;
            font-size: 18px;
            color: $grey;
            letter-spacing: 0;
        }
    }
}

.picklist-drawer__main__picklist {
    &__container {
        margin-top: 2rem;
        padding: 2rem;
        max-height: 50rem;
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        margin-block: 1rem;

        p {
            text-transform: uppercase;
            margin: 0;
            padding: 0;
        }

        svg {
            cursor: pointer;
            width: 1em;
            fill: $primary-color;
        }
    }
}

.picklist-drawer__main__picklist__search {

    .search__bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 4rem;
        width: 100%;

        form {
            width: 100%;
            height: 4rem;
        }

        .search__button {
            background-color: $primary-color;
            margin: 0;
            margin-right: 0.05rem;
            padding: 0.8rem;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            aspect-ratio: 1;

            svg {
                width: 1.7rem;
                height: 1.7rem;
                fill: $white;
            }
        }

        .input,
        .wzmh-input-affix-wrapper {
            height: 4rem;
            width: 100%;
            border: none !important;
        }

        input {
            height: 3.2rem;
            width: 100%;
        }
    }

    .search__bar {
        border: 1px solid $primary-light-90;
        border-radius: 10px;

        &:focus-within {
            border-color: $primary-color;

            .search__bar__field {
                svg {
                    fill: $primary-color;
                }
            }
        }
    }
}

.picklist-drawer__main-picklist-select {
    margin-top: 1rem;
    overflow-y: scroll;
    max-height: 45rem;

    .picklist-drawer__no-picklist {
        margin-top: 2rem;
        text-align: center;
        font-size: 1.4rem;
    }

    .wzmh-checkbox-wrapper {
        width: 100%;
        margin-block: 1rem;
    }
}