@import 'src/styles/variables';

.activity-log {
    font-family: 'AvenirMedium';
    color: $grey-2;
    font-size: 1.2rem;
    margin-bottom: 3.2rem;
    width: 100%;

    &__message {
        font-size: 1.4rem;
        margin-top: -0.9rem;
        width: 80ch;

        &__user {
            color: $primary-black;
        }

        &__text {
            color: $primary-color;

            &.gray-color {
                color: $grey-2;
            }
        }
    }

    &__heirarchy {
        margin-top: 0.15rem;

        .breadcrumb--sm .wzmh-breadcrumb-link {
            color: $grey-2;
        }
    }

    .activity-log__action.active {
        display: flex;
    }

    &:hover {
        .activity-log__action {
            display: flex;
        }
    }
}

.activity-log__date__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
        width: 1.2rem;
        height: 1.2rem;
    }

    .activity-log__action {
        display: none;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
    }
}

.activity-log__detailed__container {
    width: 80ch;
    overflow-y: hidden;
    background-color: $grey-5;
    border-radius: 10px;
    transition: height ease-in-out 0.3s;

    .old-data {
        margin-bottom: 1.8rem;
    }

    &.active {
        height: auto;
        padding: 2rem 2rem;
    }

    &.inactive {
        height: 0;
    }

    .activity-log__detailed {
        &--title {
            text-transform: uppercase;
            letter-spacing: 1.2px;
        }

        &--text {
            line-height: 180%;
            color: $grey;
        }
    }
}