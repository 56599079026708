.image-cropper {
  max-height: 500px;
  display: flex;
  justify-content: center;
  z-index: 1000;

  img {
    max-width: 100%;
    max-height: 500px;
    object-fit: contain;
  }
}
