@import "src/styles/variables";

.notification-container {
  // margin: 2.6rem 10rem;

  .notification-page__heading-container {
    display: flex;
    align-items: center;
    padding: 2.6rem 10rem;
    justify-content: space-between;
    width: 90%;
  }

  .heading {
    text-align: left;
    font-family: "DM Sans";
    font-weight: 300 !important;
    font-size: 3.5rem;
    color: $grey-6;
    opacity: 1;
  }

  &__edit-icon:hover {
    cursor: pointer;
  }

  .icon--edit {
    width: 2.5rem;
    height: 2.5rem;
    border: 2px solid $grey-10;
    padding: 6px;
    

    .cls-1 {
      fill: $grey-6 !important;
      stroke-width: 0px;
    }

    .cls-2 {
      fill: none !important;
      stroke: white !important;
      stroke-miterlimit: 10;
      stroke-width: .6px;
    }

    &:hover {
      .cls-1 {
        fill: $primary-color !important;
        stroke-width: 0px;
      }
    }
  }
}
