@import "src/styles/variables";

.loader {
    min-height: calc(100vh - $navbar-height);
    overflow: hidden;
    inset-block-start: 0;
}

.view-product {
    background-color: $white;
    padding: 2.4rem 4rem;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
}