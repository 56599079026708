@import "src/styles/variables";

.number-range-input {

    &__label {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__value {
        border: 1px solid $border-color;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}