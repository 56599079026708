@import "src/styles/variables";
@import "../Input/input.scss";

.text-area {
    display: flex;
    flex-direction: column;
    gap: 7px;

    textarea {
        padding: 1.2rem 1.6rem;

        &:hover {
            border-width: 2px;
        }

        &::placeholder {
            opacity: 0.4;
        }
    }
}