@import "src/styles/variables";

.dimension-and-cost {
  .wzmh-collapse-content-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin-top: 2.4rem;

    .text-grey-2 {
      font-size: 1.4rem;
      width: 50%;
    }

    .dimension-and-cost__value {
      font-size: 1.5rem;
      width: 50%;

      &__dropdown {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .ant-dropdown-trigger {
          padding: 0;
        }
      }
    }
  }

  &__value {
    &__dropdown {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      .ant-dropdown-trigger {
        padding: 0;
      }
    }
  }
}
