@import "src/styles/variables";

.category-container {
    padding: 0 10rem 3.4rem 10rem;
    cursor: pointer;

    &__empty-1 {
        height: 2.4rem;
        border-bottom: 1px solid $primary-light-90;
    }

    &__empty-2 {
        height: 3.4rem;
    }

    .category-card {
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__title {
                text-align: left;
                font-family: 'DM Sans';
                font-size: 3.5rem;
                letter-spacing: 0;
                color: $grey-11;
            }

            &__view-all {
                text-align: right;
                font-family: 'DM Sans';
                font-size: 1.6rem;
                letter-spacing: 0;
                color: $primary-color;
                opacity: 1;
            }
        }

        .subcategory-list {
            display: flex;
            position: relative;

            .scroll-to-next-set {
                width: 3.2rem;
                position: absolute;
                right: -1.6rem;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                &__icon {
                    height: 3.2rem;
                    background-color: $black-light-10;
                    color: $white;
                    width: 3.2rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .icon--right-arrow {
                        width: 0.7rem;
                        height: 1.2rem;
                        fill: $white;
                    }
                }
            }

            .prev {
                left: -1.9rem
            }

            &__contain {
                overflow-y: scroll;
                padding-bottom: 1rem;

                &__content-list {
                    display: flex;
                    justify-content: space-evenly;
                    align-items: center;
                    cursor: auto;

                    &__content {
                        display: flex;
                        flex-direction: column;
                        gap: 1.5rem;

                        &__title {
                            letter-spacing: 0;
                            color: $grey-6;
                            opacity: 1;
                            margin-top: 1.6rem;
                            text-align: center;
                            background-image: linear-gradient(120deg, #000 0%, #000 100%);
                            background-repeat: no-repeat;
                            background-size: 100% 0em;
                            background-position: 0 88%;
                            transition: background-size 0.25s ease-in;
                        }

                        &__image {
                            display: inline-block;

                            &,
                            img {
                                aspect-ratio: 1;
                                object-fit: cover;
                                height: 16rem;
                            }
                        }
                    }

                }
            }
        }

        .react-multiple-carousel__arrow{
            z-index: 5;
        }
    }


}

.jus {
    justify-content: center;
    align-items: center;
    display: flex;
}

.hover-animation-cat {
    transition: all 300ms ease-in-out;
    cursor: pointer;

    &:hover {
        transform: translate(0, -14px);
    }
}

.hover-animation-cat:hover .subcategory-list__contain__content-list__content__title {
    font-family: 'DM Sans';
    background-size: 100% 0.07em;
    transition: all 300ms ease-in-out;
}

.rmc {
    &__container {
        padding-block: 3rem 0;

        ul {
            gap: 8px;
        }
    }
}

.react-multiple-carousel {
    &__arrow {
        width: 32px !important;
        aspect-ratio: 1 !important;
        min-width: auto !important;
        min-height: auto !important;

        display: flex;
        justify-content: center;
        align-items: center;

        &--left {
            left: 0 !important;

            &::before {
                padding-top: 2px;
                font-size: 14px !important;
            }
        }

        &--right {
            right: 0 !important;

            &::before {
                padding-top: 2px;
                font-size: 14px !important;
            }
        }
    }
}

.subcategory-list__contain__content-list__content__title {
    font-family: "DM Sans";
    font-weight: 300;
}