@import "src/styles/variables";

.grouped-input {
    align-content: center;
    display: grid;
    grid-template-columns: auto 1fr;

    &__item {
        align-items: center;
        border: 1px solid $grey-4;
        border-top-width: 0;
        display: flex;

        &:nth-child(1) {
            border-top-left-radius: 8px;
            border-top-width: 1px;
        }

        &:nth-child(2) {
            border-top-right-radius: 8px;
            border-top-width: 1px;
        }

        &:nth-last-child(2) {
            border-bottom-left-radius: 8px;
        }

        &:nth-last-child(1) {
            border-bottom-right-radius: 8px;
        }
    }

    &__label {
        background-color: $grouped-input-label-bg;
        color: $grey-2;
        padding-block: 1.1rem 1rem;
        padding-inline: 1.6rem;
    }

    &__labelSmall{
        background-color: $grouped-input-label-bg;
        color: $grey-2;
        padding-block: 0.35rem 0.35rem;
        padding-inline: 1.6rem;
    
    }
    &__value {
        border-left: 0;
        padding-inline: 1.25rem 1.25rem;
    }

    &--sm {
        &__label {
            padding-inline: 1.2rem;
        }

        &__value {
            padding-inline: 2rem;
        }
    }
}
.product-form-content-right{
    .grouped-input__value{
        padding-inline: 0 1.25rem;
    }
}