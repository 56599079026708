@import "src/styles/variables";

.variant-details {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;

  &__columns {
    display: flex;
    width: 100%;
    position: relative;
  }

  &__left-column {
    flex: 1;
    padding-right: 2.4rem;
    border-right: 1px solid #919496;
  }

  &__divider {
    position: absolute;
    width: 1px;
    background-color: $grey-3;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &__right-column {
    flex: 1;
    padding-left: 2.4rem;
  }

  &__card {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1.6rem;
    margin-bottom: 2.4rem;
  }

  &__section-title {
    font-size: 2rem;
    // font-family: "AvenirHeavy", sans-serif;
    font-weight: 500;
    margin-bottom: 1.6rem;
    color: black;
  }

  &__related-accessories {
    margin-bottom: 2.4rem;

    p {
      font-size: 15px;
      line-height: 1.6;
      color: $grey-2;
    }
  }
}

// Ensure both columns scroll together
.variant-details__left-column,
.variant-details__right-column {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

.variant-details__labels_label {
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
  // font-family: "AvenirMedium", sans-serif;
  font-family: inherit !important;
}

.variant-details__values_label {
  font-size: 1.5rem;
  font-weight: 400;
  color: #595959;
  // font-family: "AvenirMedium", sans-serif;
  font-family: inherit !important;
}
