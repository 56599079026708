@import "src/styles/variables";

.grouped-checklist {
    &__title {
        font-family: "AvenirHeavy";
        font-size: 1.8rem;
        text-transform: capitalize;
        margin-bottom: 2.4rem;
    }

    &__content {
        padding-bottom: 1.6rem;
        border-bottom: 1px solid $grey-3;
        margin-bottom: 1.6rem;

        &__title {
            color: $grey-2;
            font-size: 1.2rem;
            letter-spacing: 0.12rem;
            text-transform: uppercase;
            margin-bottom: 1.6rem;
        }

        &__list {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;
        }
    }
}