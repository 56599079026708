@import "src/styles/variables";

.category-container {
  padding: 0 10rem 3.4rem 10rem;
  cursor: pointer;

  &__empty-1 {
    height: 2.4rem;
    border-bottom: 1px solid $primary-light-90;
  }

  &__empty-2 {
    height: 3.4rem;
  }

  .category-card {
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2.4rem;

      &__title {
        text-align: left;
        font-family: "DM Sans";
        font-size: 3.5rem;
        letter-spacing: 0;
        color: $grey-11;
        font-weight: 300;
      }

      &__view-all {
        font-size: 1.6rem;
        color: $primary-color;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.subcategory-list__contain__content-list {
  cursor: pointer;
  padding: 0.8rem;

  &__content {
    &__image {
      width: 100%;
      aspect-ratio: 1;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 1.6rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__title {
      font-family: "DM Sans";
      font-weight: 300;
      font-size: 2rem;
      color: $grey-11;
      margin: 0;
      text-align: center;
    }
  }

  .hover-animation-cat {
    transition: transform 0.3s ease;
    &:hover {
      transform: translateY(-14px);
    }
  }
}

.rmc__container {
  padding: 1rem 0;
}
