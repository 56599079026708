@import "src/styles/variables";

.plan-details {

    &__header {
        padding-block: 0.7rem 0.6rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
            color: $grey;
            font-size: 2rem;
            font-family: "AvenirHeavy";
            letter-spacing: 0;
        }
    }

    &__content {
        &__description {
            color: $grey-2;
            letter-spacing: 0;
            line-height: 2.4rem;
        }
    }
}